<template>
    <el-affix :offset="0">
        <div class="headerContainer">
            <div class="headerContainer_left" @click="goHome">
                <span class="headerContainer_left_img"><img src="../assets/images/home/logo.png" alt=""></span><span class="headerContainer_left_title">ECO Carbon Tracer</span>
            </div>
            <div class="headerContainer_right">
                <span v-if="isLogin === 0" class="headerContainer_right_login" @click="handleLogin">login</span>
                <span v-if="isLogin === 0" class="headerContainer_right_sign" @click="handleSignUp">sign up</span>
                <span v-if="isLogin === 1" class="headerContainer_right_avatar" @click="handleAvatar">
                    <img :src="avatar" alt="">
                </span>
            </div>
        </div>
    </el-affix>
</template>

<script setup name="Header">
import { defineProps, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getToken, getUserId } from '@/utils/auth'
import { getUserInfo, getLoginInfo } from '@/api/user'
const router = useRouter()
defineProps({ title: String })
const isLogin = ref(0)
// type 用户类型 1 供应商 2 客户
const type = ref('')
const avatar = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/head.png')
// 判断用户是否登录
const checkLogin = async () => {
  if (getToken()) {
    // 已经登录
    isLogin.value = 1
    // 查询当前注册进程
    const res = await getUserInfo(getUserId())
    type.value = res?.data?.registerStep?.type
  } else {
    isLogin.value = 0
  }
}
checkLogin()
// 跳转登录
const handleLogin = () => {
  router.push({
    path: 'login',
    query: {
      id: 'login'
    }
  })
}

// 跳转注册
const handleSignUp = () => {
  router.push({
    path: 'login',
    query: {
      id: 'signUp'
    }
  })
}

// 跳转个人中心 1=supplier 2=client
const handleAvatar = () => {
  if (type.value === '1') {
    router.push({
      path: 'userCenter',
      query: {
        userType: 1
      }
    })
  } else {
    router.push({
      path: 'userCenter',
      query: {
        userType: 2
      }
    })
  }
}

// 跳转首页
const goHome = () => {
  router.push({
    path: '/',
    query: {
    }
  })
}

const getUser = async () => {
  const res = await getLoginInfo()
  if (res.code === 200) {
    if (res.data.sysUser?.avatar != null && res.data.sysUser?.avatar !== '') {
      avatar.value = res.data.sysUser.avatar
    }
  }
}
getUser()

</script>

<style lang="scss" scoped>
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    padding: 25px 200px 25px 200px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

    &_left {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        &_title {
            color: rgba(16, 16, 16, 1);
            font-size: 24px;
            text-align: left;
            margin-left: 20px;
            font-weight: bold;
        }

        img {
            height: 80px;
            width: 80px;
        }
    }

    &_right {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
            cursor: pointer;
        }

        &_login {
            color: rgba(24, 24, 24, 1);
            font-size: 14px;
            text-align: center;
        }

        &_sign {
            padding: 12px 20px 12px 20px;
            line-height: 20px;
            border-radius: 4px;
            background-color: rgba(39, 174, 96, 1);
            text-align: center;
            margin-left: 40px;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
        }

        &_avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: rgba(39, 174, 96, 1);
            overflow: hidden;
        }

        img {
            width: 60px;
            height: 60px;
            object-fit: cover;
        }
    }
}
</style>

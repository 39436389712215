import { render, staticRenderFns } from "./Amap.vue?vue&type=template&id=921abfce&scoped=true"
import script from "./Amap.vue?vue&type=script&setup=true&name=Amap&lang=js"
export * from "./Amap.vue?vue&type=script&setup=true&name=Amap&lang=js"
import style0 from "./Amap.vue?vue&type=style&index=0&id=921abfce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "921abfce",
  null
  
)

export default component.exports
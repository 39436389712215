<!-- 报价单详情 - 样品单 -->
<template>
  <div class="container">
    <div class="container_progress">
        <div class="container_progress_top">
          <!-- 发货国家 发货时间 -->
          <div class="container_progress_top_left">
            <p class="container_progress_top_title">China</p>
            <p class="container_progress_top_date">{{ timeline[0]?.createTime }}</p>
          </div>

          <!-- 预计到达 -->
          <div class="container_progress_top_right">
            <p class="container_progress_top_title">Ireland</p>
            <p class="container_progress_top_date">{{ timeline[timeline.length - 1]?.createTime }}</p>
          </div>
        </div>

        <!-- 进度  -->
        <div ref="scrollContainer" class="container_progress_bottom" @mousewheel="scrollHorizontally">

          <div ref="scrollContent" class="container_progress_bottom_box" v-if="order.orderType === 1">
            <div class="container_progress_bottom_box_card" v-for="(item, index) in stateOptionsOnlySample " :key="index">
              <div class="container_progress_bottom_box_card_line">
                <div :class="['container_progress_bottom_box_card_line_round', index-1 == orderStatus ? 'container_progress_bottom_box_card_line_roundActive' : 'container_progress_bottom_box_card_line_roundAlready',
                  index-1 > orderStatus ? 'container_progress_bottom_box_card_line_roundNot' : '']">
                </div>
              </div>
              <div :class="['container_progress_bottom_box_card_item', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive' : '']">
                <div class="container_progress_bottom_box_card_item_top">
                  <div class="container_progress_bottom_box_card_item_top_co2">
                    <div class="container_progress_bottom_box_card_item_top_co2_icon">
                      <img v-show="index-1 != orderStatus" src="../../assets/images/home/carbonFootprint.png" alt="">
                      <img v-show="index-1 == orderStatus" src="../../assets/images/home/carbonFootprint_white.png" alt="">
                    </div>
                    <div class="container_progress_bottom_box_card_item_top_co2_text">
                      <p :class="['container_progress_bottom_box_card_item_top_co2_text_title', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_top_co2_text_title' : '']">
                        {{ thousands(item.co) }}kg</p>
                      <p :class="['container_progress_bottom_box_card_item_top_co2_text_text', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_top_co2_text_text' : '']">
                        CO<sub>2</sub></p>
                    </div>
                  </div>
                  <div class="container_progress_bottom_box_card_item_top_lable">
                    <img v-show="index-1 != order.orderStatus" src="../../assets/images/home/lable.png" alt="">
                    <img v-show="index-1 == order.orderStatus" src="../../assets/images/home/lable1.png" alt="">
                  </div>
                </div>

                <!-- 状态 -->
                <div :class="['container_progress_bottom_box_card_item_status', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_status' : '']">
                  {{ item.state }}
                </div>

                <!-- 最后是日期和地点 -->
                <div :class="['container_progress_bottom_box_card_item_bottom', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_bottom' : '']">
                  <div class="container_progress_bottom_box_card_item_bottom_date">
                    {{ item.time }}
                  </div>
                  <!--                  <div class="container_progress_bottom_box_card_item_bottom_location">-->
                  <!--                    China-->
                  <!--                  </div>-->
                </div>

              </div>
            </div>
          </div>

          <div ref="scrollContent" class="container_progress_bottom_box" v-if="order.orderType === 2">
            <div class="container_progress_bottom_box_card" v-for="(item, index) in stateOptions " :key="index">
              <div class="container_progress_bottom_box_card_line">
                <div :class="['container_progress_bottom_box_card_line_round', index-1 == orderStatus ? 'container_progress_bottom_box_card_line_roundActive' : 'container_progress_bottom_box_card_line_roundAlready',
                  index-1 > orderStatus ? 'container_progress_bottom_box_card_line_roundNot' : '']">
                </div>
              </div>
              <div :class="['container_progress_bottom_box_card_item', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive' : '']">
                <div class="container_progress_bottom_box_card_item_top">
                  <div class="container_progress_bottom_box_card_item_top_co2">
                    <div class="container_progress_bottom_box_card_item_top_co2_icon">
                      <img v-show="index-1 != orderStatus" src="../../assets/images/home/carbonFootprint.png" alt="">
                      <img v-show="index-1 == orderStatus" src="../../assets/images/home/carbonFootprint_white.png" alt="">
                    </div>
                    <div class="container_progress_bottom_box_card_item_top_co2_text">
                      <p :class="['container_progress_bottom_box_card_item_top_co2_text_title', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_top_co2_text_title' : '']">
                        {{ thousands(item.co) }}kg</p>
                      <p :class="['container_progress_bottom_box_card_item_top_co2_text_text', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_top_co2_text_text' : '']">
                        CO<sub>2</sub></p>
                    </div>
                  </div>
                  <div class="container_progress_bottom_box_card_item_top_lable">
                    <img v-show="index-1 != order.orderStatus" src="../../assets/images/home/lable.png" alt="">
                    <img v-show="index-1 == order.orderStatus" src="../../assets/images/home/lable1.png" alt="">
                  </div>
                </div>

                <!-- 状态 -->
                <div :class="['container_progress_bottom_box_card_item_status', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_status' : '']">
                  {{ item.state }}
                </div>

                <!-- 最后是日期和地点 -->
                <div :class="['container_progress_bottom_box_card_item_bottom', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_bottom' : '']">
                  <div class="container_progress_bottom_box_card_item_bottom_date">
                    {{ item.time }}
                  </div>
                  <!--                  <div class="container_progress_bottom_box_card_item_bottom_location">-->
                  <!--                    China-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div>

          <div ref="scrollContent" class="container_progress_bottom_box" v-if="order.orderType === 3">
            <div class="container_progress_bottom_box_card" v-for="(item, index) in stateOptionsWithSample " :key="index">
              <div class="container_progress_bottom_box_card_line">
                <div :class="['container_progress_bottom_box_card_line_round', index-1 == orderStatus ? 'container_progress_bottom_box_card_line_roundActive' : 'container_progress_bottom_box_card_line_roundAlready',
                  index-1 > orderStatus ? 'container_progress_bottom_box_card_line_roundNot' : '']">
                </div>
              </div>
              <div :class="['container_progress_bottom_box_card_item', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive' : '']">
                <div class="container_progress_bottom_box_card_item_top">
                  <div class="container_progress_bottom_box_card_item_top_co2">
                    <div class="container_progress_bottom_box_card_item_top_co2_icon">
                      <img v-show="index-1 != orderStatus" src="../../assets/images/home/carbonFootprint.png" alt="">
                      <img v-show="index-1 == orderStatus" src="../../assets/images/home/carbonFootprint_white.png" alt="">
                    </div>
                    <div class="container_progress_bottom_box_card_item_top_co2_text">
                      <p :class="['container_progress_bottom_box_card_item_top_co2_text_title', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_top_co2_text_title' : '']">
                        {{ thousands(item.co) }}kg</p>
                      <p :class="['container_progress_bottom_box_card_item_top_co2_text_text', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_top_co2_text_text' : '']">
                        CO<sub>2</sub></p>
                    </div>
                  </div>
                  <div class="container_progress_bottom_box_card_item_top_lable">
                    <img v-show="index-1 != order.orderStatus" src="../../assets/images/home/lable.png" alt="">
                    <img v-show="index-1 == order.orderStatus" src="../../assets/images/home/lable1.png" alt="">
                  </div>
                </div>

                <!-- 状态 -->
                <div :class="['container_progress_bottom_box_card_item_status', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_status' : '']">
                  {{ item.state }}
                </div>
                <div :class="['container_progress_bottom_box_card_item_bottom', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_bottom' : '']">
                  <div class="container_progress_bottom_box_card_item_bottom_date">
                    Expected: {{ item.timeLineA }}
                  </div>
                  <!--                  <div class="container_progress_bottom_box_card_item_bottom_location">-->
                  <!--                    China-->
                  <!--                  </div>-->
                </div>
                <!-- 最后是日期和地点 -->
                <div :class="['container_progress_bottom_box_card_item_bottom', index-1 == orderStatus ? 'container_progress_bottom_box_card_itemActive_bottom' : '']">
                  <div class="container_progress_bottom_box_card_item_bottom_date">
                    Actual: {{ item.timeLineB }}
                  </div>
                  <!--                  <div class="container_progress_bottom_box_card_item_bottom_location">-->
                  <!--                    China-->
                  <!--                  </div>-->
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

    <div class="container_content">
      <!-- 左侧图片 -->
      <div class="container_content_left">
        <img :src="currentImgSrc" alt="">
        <div class="container_content_left_imgs">
          <img v-for="(img, index) in imgList" :key="index" :src="img" @click="changeImg(img)" :class="{ 'container_content_left_imgs_isSelected': img === currentImgSrc }" alt="">
        </div>
        <div class="container_content_left_carbon-tracking" @click="carbonTracking" v-if="orderEntity != null && quotationId != null">
          <span>Carbon Tracking</span>
          <img src="../../assets/images/home/toRight_green.png" alt="">
        </div>
        <!--        <div class="container_content_left_btn">-->
        <!--          Sample delivery-->
        <!--        </div>-->
        <div class="container_content_left_btn1" @click="$router.go(-1)">
          Back
        </div>
        <div class="container_content_left_btn2" v-if="showDeleteButton" @click="deleteRequirementMethod">
          Delete
        </div>
      </div>

      <!-- 右侧 -->
      <div class="container_content_right">

        <!-- 需求详情  requirement-->
        <div class="container_content_right_requirement requirement">

          <!-- 标题 -->
          <div class="container_content_right_requirement_titleBox requirement_titleBox">
            <div class="container_content_right_requirement_titleBox_title">{{ demand.productName }}</div>
            <!-- <div class="container_content_right_requirement_titleBox_quantity"><span>Quantity</span>{{ demand.quantity
            }}
            </div> -->
          </div>

          <!-- 详情 -->
          <div class="container_content_right_requirement_details" v-show="isShowRequirementDetail">
            <div class="container_content_right_requirement_details_list" v-for="(item, index) in keys" :key="item.key">
              <div class="container_content_right_requirement_details_list_title">{{ getTitle(item) }}</div>
              <div class="container_content_right_requirement_details_list_value">{{ getDisplayedValue(item) }}</div>
            </div>
            <!--						<div class="container_content_right_requirement_details_list file">-->
            <!--							<div class="file_left">-->
            <!--								<div class="container_content_right_requirement_details_list_title">Original Artwork-->
            <!--								</div>-->
            <!--								<div class="container_content_right_requirement_details_list_value">Design draft.ai-->
            <!--								</div>-->
            <!--							</div>-->
            <!--							<div class="file_download">Download</div>-->
            <!--						</div>-->
          </div>

          <!-- 产品2和后面的产品 -->
          <div class="container_content_right_requirement_details" v-for="(product, productIndex) in productInfoList" :key="productIndex">
            <div class="container_content_right_requirement_details_title">
              {{ product.productName }}
            </div>
            <div class="container_content_right_requirement_details_list" v-for="(item, index) in keys" :key="item.key">
              <div class="container_content_right_requirement_details_list_title">{{ getTitle(item) }}</div>
              <div class="container_content_right_requirement_details_list_value">{{ getDisplayedValue2(productIndex, item) }}</div>
            </div>
          </div>

          <!-- 需求单通用数据 -->
          <div class="container_content_right_requirement_details commonData">
            <div class="container_content_right_requirement_details_list commonData_list" v-for="(item, index) in commonData" :key="item.key">
              <div class="container_content_right_requirement_details_list_title">{{ getCommonTitle(item) }}</div>
              <div class="container_content_right_requirement_details_list_value">{{ getDisplayedValue(item) }}</div>
            </div>
          </div>

          <!-- Expand/Collapse -->
          <!-- <div class="container_content_right_requirement_expand" @click="showRequirementDetail">
            <div class="container_content_right_requirement_expand_box">
              <span>Expand</span>
              <img src="../../assets/images/customer/Expand.png" alt="">
            </div>
          </div> -->

          <!--          <div>-->
          <!--            <router-link class="btn btn-back" to="javascript:void(0);" @click="$router.go(-1)">-->
          <!--              <i class="fa fa-arrow-left"></i> Back-->
          <!--            </router-link>-->
          <!--          </div>-->
        </div>

        <!-- 报价单  quotation-->
        <div class="container_content_right_requirement quotation" v-if="quotationId != null">

          <!-- 标题 -->
          <div class="container_content_right_requirement_titleBox">
            <div class="container_content_right_requirement_titleBox_title">Quotation</div>
            <div class="container_content_right_requirement_titleBox_left" @click="openCarbonValue">
              <img src="../../assets/images/home/carbonFootprint_white.png" alt="">
              <span>{{ thousands(quotation.totalco) }}kg</span>
              <span class="co">CO<sub>2</sub> </span>
              <img src="../../assets/images/customer/white_right.png" alt="">
            </div>
          </div>

          <!-- 详情 -->
          <div class="container_content_right_requirement_details">
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Company Name</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.supplier?.supplierName }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">HS code</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.hscode }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Estimated Time of Delivery</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.productionDuration }}</div>
            </div>
            <div class="container_content_right_requirement_details_list" v-if="userType === '2' && orderType2 === 2">
              <div class="container_content_right_requirement_details_list_title">Actual Delivery Time</div>
              <div class="container_content_right_requirement_details_list_value">{{ actualDeliveryTime }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Sample Charge</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.sampleUnitPrice == null ? "" : currency }} {{ quotation.sampleUnitPrice }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Sample Lead Time</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.sampleGenerationPeriod }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Pcs/Ctn</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.pcsCtnSingle }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Carton Size (mm)</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.ctnSizeSingle }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Weight/Carton (kg)</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.weightCtnSingle }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Total CBM (m<sup>3</sup>)</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.totalCbm }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Gross Weight (kg)</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.totalGrossWeight }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">FOB/CIF (unit)</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.fobcif }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Special Charges</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.specialCharges }}</div>
            </div>
            <div class="container_content_right_requirement_details_list" v-if="quotation.specialCharges !== 'None'">
              <div class="container_content_right_requirement_details_list_title">Charge Amount</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.chargeAmount == null ? "" : currency }} {{ quotation.chargeAmount }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Material</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.ekoTpfsjChina?.catalog2
              }}
              </div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Material Usage</div>
              <div class="container_content_right_requirement_details_list_value">{{ quotation.materialUsage }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Unit Price</div>
              <div class="container_content_right_requirement_details_list_value">{{ currency }} {{ thousand(quotation.unitPrice) }}</div>
            </div>
            <div class="container_content_right_requirement_details_list">
              <div class="container_content_right_requirement_details_list_title">Price</div>
              <div class="container_content_right_requirement_details_list_value">{{ currency }} {{ thousand(quotation.totalprice) }}</div>
            </div>
            <!-- 不接受报价和接受报价按钮 -->
            <div class="container_content_right_requirement_details_list_btn" v-show="quotation.status !== 3 && quotation.status !== 4 && orderType === ''">

              <div class="container_content_right_requirement_details_list_btn_reject" @click="openContact">
                <img src="../../assets/images/customer/contact.png" alt="">
                Contact
              </div>
              <div class="container_content_right_requirement_details_list_btn_reject" @click="openRejectQuotation">
                Decline
              </div>
              <div class="container_content_right_requirement_details_list_btn_accept" @click="openSampleQuantity">
                Accept
              </div>
            </div>

            <!-- 已签大货单，不需要样品 -->
            <div class="container_content_right_requirement_details_list_status" v-show="orderType === 2">
              <img src="../../assets/images/supplier/check-circle.png" alt="">
              <span>Quotation accepted,awaitng mass production.</span>
            </div>

            <!-- 已签大货单，申请了样品 -->
            <!-- <div
              class="container_content_right_requirement_details_list_status"
              v-show="orderEntity?.orderType === 3"
            >
              <img
                src="../../assets/images/supplier/check-circle.png"
                alt=""
              >
              <span>Quote Accepted, waiting for sample shipment</span>
            </div> -->
            <div class="container_content_right_requirement_details_list_status" v-show="quotation.status === 3 && orderType !== 2">
              <img src="../../assets/images/supplier/check-circle.png" alt="">
              <span>Quote Accepted, waiting for sample shipment</span>
            </div>

            <!-- 已拒绝 -->
            <div class="container_content_right_requirement_details_list_status" v-show="quotation.status === 4">
              <img src="../../assets/images/supplier/emotion-unhappy.png" alt="">
              <span class="reject">this quotataion would be declined,you might net able to restore it.</span>
            </div>

            <!-- 只样品单 -->
            <!-- <div
              class="container_content_right_requirement_details_list_status"
              v-show="orderType === 1"
            >
              <img
                src="../../assets/images/supplier/check-circle.png"
                alt=""
              >
              <span>Quote Accepted, waiting for sample shipment</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 查看碳值的弹窗 -->
    <ViewCarbonValue v-if="quotation.quotationId" v-on:customEvent="closeCarbonValue" v-show="showCarbonValue" :quotation="quotation" />

    <!-- 联系，发送内容的弹窗 -->
    <ContactPopup v-on:customEvent="closeContact" v-show="showContact" :info="info" />

    <!-- 签单，填写样品数量的弹窗 -->
    <SignSampleOrderPopup v-on:customEvent="closeSampleQuantity" v-show="showSampleQuantity" :quotationId="quotationId" />

    <!-- 不接受报价的弹窗 -->
    <RejectQuotation v-on:customEvent="closeRejectQuotation" v-show="showRejectQuotation" :detael="quatationDetailId" />

    <!-- foot   -->
    <Foot />
  </div>
</template>

<!-- 报价单详情 -->
<script setup name="QuotationDetail">
import { ref, reactive, provide, computed } from 'vue'
import Foot from '../../components/Foot.vue'

// 碳值弹窗
import ViewCarbonValue from './dialog/ViewCarbonValue.vue'

// 签大货单，询问是否需要样品的弹窗
import ContactPopup from './dialog/ContactPopup.vue'

// 签样品单，填写样品数量
import SignSampleOrderPopup from './dialog/SignSampleOrderPopup.vue'

// 不接受报价的弹窗
import RejectQuotation from './dialog/OperationTips.vue'
import { getQuotationDetail } from '@/api/quotation'
import { getDemandDetail, deleteRequirement } from '@/api/demand'
import { useRoute, useRouter } from 'vue-router'
import { getDict } from '@/api/common'
import { getMaterialList, getSysDictList } from '@/api/user'
import { allowOrRefuseQuo, getOrderByQuotationId, getOrderTimeLines, getOrderDetail, getOrderTimeLineAll } from '@/api/order'
import { thousands } from '@/utils/thousands'
import { getUserId, getUserType } from '@/utils/auth'
import { thousand } from '@/utils/thousand'
import { ElMessageBox } from 'element-plus'
const showDeleteButton = ref(false)
const router = useRouter()
const route = useRoute()
const orderType = ref('')
const requirementId = route.query.requirementId
const quotationId = route.query.quotationId
let orderId = route.query.orderId
const demand = ref({})
const deliveryAddress = ref()
const materialId = ref()
const currency = ref('')
const orderType2 = ref(0)
// 切换图片
const imgList = ref([])
const timeline = ref([])
const order = ref({})
const scrollContainer = ref(null)
const scrollContent = ref(null)
const orderStatus = ref(0)
const currentImgSrc = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodavt.png')
const info = ref({ requirementId: requirementId, quotationId: quotationId })
const stateOptionsOnlySample = ref([])
const stateOptions = ref([])
const stateOptionsWithSample = ref([])

const scrollHorizontally = (event) => {
  event.preventDefault()
  const container = scrollContainer.value
  const content = scrollContent.value
  const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)))
  container.scrollLeft -= (delta * 30)
}

const getDisplayedValue = (key) => {
  const value = demand.value[key.key]
  switch (key.key) {
  case 'needSample':
    return value === 1 ? 'YES' : 'NO'
  case 'material':
    const matchedMaterial = materials.value.find(material => material.id === materialId.value)
    return matchedMaterial?.catalog2
  case 'DeliveryAddress':
    return deliveryAddress.value
  default:
    return value
  }
}

const userType = ref('0')
const initUserType = () => {
  userType.value = getUserType().toString()
}
initUserType()

const keys = ref([
  // { name: 'Product Name', key: 'productName' },
  { name: 'Quantity', key: 'quantity' },
  { name: 'Product Size (mm)', key: 'specification' },
  { name: 'Material', key: 'material' },
  { name: 'Colour', key: 'pantoneCode' },
  { name: 'Process Requirement', key: 'processRequirement' },
  { name: 'Other Requirement', key: 'otherSpecialRequirent' },
  { name: 'Remark', key: 'remark' }
  // { name: 'Overall Budget', key: 'overallBudget' },
  // { name: 'Lead Time Requirement', key: 'expectedDeliveryDate' },
  // { name: 'Delivery address', key: 'deliveryAddressId' }
  // { name: 'Sample requirment', key: 'needSample' }
])

const currentStatus = async () => {
  const result = await getOrderDetail(orderId)
  if (result.code === 200) {
    order.value = result.data
    console.log(order.value)
    if (order.value.orderType === 1) {
      // 样品
      if (order.value.orderStatus === 101 || order.value.orderStatus === 102 || order.value.orderStatus === 103 || order.value.orderStatus < 9) {
        isConfirm.value = true
      } else {
        isConfirm.value = false
      }
    } else {
      // 非样品单
      if (order.value.orderStatus === 101 || order.value.orderStatus === 102 || order.value.orderStatus === 103 || order.value.orderStatus < 9) {
        isConfirm.value = true
      } else {
        isConfirm.value = false
      }
    }
    console.log('isConfirm', isConfirm.value)
  }
}
currentStatus()

const getOrderTimeLine = async () => {
  const results = await getOrderTimeLineAll(orderId)
  const result = await getOrderTimeLines(orderId)

  console.log('timeline result==', result)
  if (result.code === 200) {
    const timelineList = result.data
    const timeMultilist = results.data
    timeline.value = timelineList
    if (timelineList.length > 0) {
      console.log('orderStatus==', order.value.orderStatus)
      console.log('orderType==', order.value.orderType)
      if (order.value.orderType === 1) {
        for (const item of timelineList) {
          // for (const items of stateOptionsOnlySample.value) {
          //   if (item.status === items.status) {
          //     items.time = item.createTime
          //   }
          // }
          for (let i = 0; i < stateOptionsOnlySample.value.length; i++) {
            if (item.status === stateOptionsOnlySample.value[i].status) {
              stateOptionsOnlySample.value[i].time = item.createTime
            }
            stateOptionsOnlySample.value[i].timeLineA = timeMultilist[i].linATime
            stateOptionsOnlySample.value[i].timeLineB = timeMultilist[i].lineBTime
          }
        }
      } else if (order.value.orderType === 2) {
        for (const item of timelineList) {
          // for (const items of stateOptions.value) {
          //   if (item.status === items.status) {
          //     items.time = item.createTime
          //   }
          // }
          for (let i = 0; i < stateOptions.value.length; i++) {
            if (item.status === stateOptions.value[i].status) {
              stateOptions.value[i].time = item.createTime
            }
            stateOptions.value[i].timeLineA = timeMultilist[i].linATime
            stateOptions.value[i].timeLineB = timeMultilist[i].lineBTime
          }
        }
      } else if (order.value.orderType === 3) {
        for (const item of timelineList) {
          // for (const items of stateOptionsWithSample.value) {
          //   if (item.status === items.status) {
          //     items.time = item.createTime
          //   }
          // }
          for (let i = 0; i < stateOptionsWithSample.value.length; i++) {
            if (item.status === stateOptionsWithSample.value[i].status) {
              stateOptionsWithSample.value[i].time = item.createTime
            }
            stateOptionsWithSample.value[i].timeLineA = timeMultilist[i].linATime
            stateOptionsWithSample.value[i].timeLineB = timeMultilist[i].lineBTime
          }
        }
      }
    }
  }
}

const lateInit = () => {
  setTimeout(() => {
    if (order.value.orderType === 1) {
      switch (order.value.orderStatus) {
      case 2:
        orderStatus.value = 0
        break
      case 101:
        orderStatus.value = 1
        break
      case 102:
        orderStatus.value = 2
        break
      case 103:
        orderStatus.value = 2
        break
      case 9:
        orderStatus.value = 2
        break
      }
    } else if (order.value.orderType === 2) {
      switch (order.value.orderStatus) {
      case 2:
        orderStatus.value = 0
        break
      case 3:
        orderStatus.value = 1
        break
      case 4:
        orderStatus.value = 2
        break
      case 5:
        orderStatus.value = 3
        break
      case 6:
        orderStatus.value = 4
        break
      case 7:
        orderStatus.value = 5
        break
      case 8:
        orderStatus.value = 6
        break
      case 9:
        orderStatus.value = 6
        break
      }
    } else if (order.value.orderType === 3) {
      switch (order.value.orderStatus) {
      case 2:
        orderStatus.value = 0
        break
      case 101:
        orderStatus.value = 1
        break
      case 102:
        orderStatus.value = 2
        break
      case 3:
        orderStatus.value = 3
        break
      case 4:
        orderStatus.value = 4
        break
      case 5:
        orderStatus.value = 5
        break
      case 6:
        orderStatus.value = 6
        break
      case 7:
        orderStatus.value = 7
        break
      case 8:
        orderStatus.value = 8
        break
      case 9:
        orderStatus.value = 8
        break
      }
    }
    stateOptionsOnlySample.value = [
      {
        state: 'Order Created',
        time: '',
        status: 0,
        co: 0,
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Raw Matrial Processing',
        time: '',
        status: 2,
        co: (order.value.choosedSupplierQuotationList ? (order.value.choosedSupplierQuotationList[0].materialCo ? order.value.choosedSupplierQuotationList[0].materialCo : 0) : 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Sample Production',
        time: '',
        status: 101,
        co: (order.value.choosedSupplierQuotationList ? (order.value.choosedSupplierQuotationList[0].sampleProductionCo ? order.value.choosedSupplierQuotationList[0].sampleProductionCo : 0) : 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Sample Shipping',
        time: '',
        status: 102,
        co: (order.value.choosedSupplierQuotationList ? (order.value.choosedSupplierQuotationList[0].sampleCo ? order.value.choosedSupplierQuotationList[0].sampleCo : 0) : 0),
        timeLineA: '',
        timeLineB: ''
      }

    ]

    stateOptions.value = [
      {
        state: 'Order Created',
        time: '',
        status: 0,
        co: 0,
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Raw Matrial Processing',
        time: '',
        status: 2,
        co: (order.value.choosedSupplierQuotationList[0].materialCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Production',
        time: '',
        status: 3,
        co: (order.value.choosedSupplierQuotationList[0].energyCo ?? 0) + (order.value.choosedSupplierQuotationList[0].materialCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Packaging',
        time: '',
        status: 4,
        co: (order.value.choosedSupplierQuotationList[0].packagingCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Inspection',
        time: '',
        status: 5,
        co: 0,
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Sending to Port of Departure',
        time: '',
        status: 6,
        co: (order.value.choosedSupplierQuotationList[0].factoryPortCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'In Transit',
        time: '',
        status: 7,
        co: (order.value.choosedSupplierQuotationList[0].internationalTransCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Local Delivery',
        time: '',
        status: 8,
        co: (order.value.choosedSupplierQuotationList[0].requireCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      }
    ]

    stateOptionsWithSample.value = [
      {
        state: 'Order Created',
        time: '',
        status: 0,
        co: 0,
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Raw Matrial Processing',
        time: '',
        status: 2,
        co: (order.value.choosedSupplierQuotationList[0].materialCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Sample Production',
        time: '',
        status: 101,
        co: (order.value.choosedSupplierQuotationList[0].sampleProductionCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Sample Shipping',
        time: '',
        status: 102,
        co: (order.value.choosedSupplierQuotationList[0].sampleCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Mass Production',
        time: '',
        status: 3,
        co: (order.value.choosedSupplierQuotationList[0].energyCo ?? 0) + (order.value.choosedSupplierQuotationList[0].materialCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Packaging',
        time: '',
        status: 4,
        co: (order.value.choosedSupplierQuotationList[0].packagingCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Inspection',
        time: '',
        status: 5,
        co: 0,
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Sending to Port of Departure',
        time: '',
        status: 6,
        co: (order.value.choosedSupplierQuotationList[0].factoryPortCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'In Transit',
        time: '',
        status: 7,
        co: (order.value.choosedSupplierQuotationList[0].internationalTransCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      },
      {
        state: 'Local Delivery',
        time: '',
        status: 8,
        co: (order.value.choosedSupplierQuotationList[0].requireCo ?? 0),
        timeLineA: '',
        timeLineB: ''
      }
    ]
    getOrderTimeLine()
  }, 1000)
}
lateInit()

// 通用数据
const commonData = reactive([
  // { name: 'Overall Budget', key: 'overallBudgetStr' },
  { name: 'Lead Time Requirement', key: 'expectedDeliveryDate' },
  { name: 'Delivery address', key: 'deliveryAddressId' }
])
const initData = () => {
  if (getUserType() === '2') {
    commonData.push({ name: 'Overall Budget', key: 'overallBudget' })
  }
}
initData()

// 获取通用数据标题
const getCommonTitle = (key) => {
  return commonData.find(item => item.key === key.key).name
}

const getTitle = (key) => {
  return keys.value.find(item => item.key === key.key).name
}

// 产品2和后面的产品的value
const getDisplayedValue2 = (index, key) => {
  const value = productInfoList[index][key.key]
  switch (key.key) {
  case 'needSample':
    return value === 1 ? 'YES' : 'NO'
  case 'material':
    const matchedMaterial = materials.value.find(material => material.id === materialId.value)
    return matchedMaterial?.catalog2
  case 'DeliveryAddress':
    return deliveryAddress.value
  default:
    return value
  }
}

// 材质列表
const materials = ref([])
// 获取材质列表
const getMaterialsList = async () => {
  const res = await getMaterialList()
  if (res.code === 200) {
    materials.value = res.data
  }
}
getMaterialsList()

// 查询报价单
const quotation = ref({})

const quotationDetail = async () => {
  if (quotationId != null) {
    const result = await getQuotationDetail(quotationId)
    if (result?.code === 200) {
      quotation.value = result?.data
      if (quotation.value.currency) {
        if (quotation.value.currency === 'EUR') {
          currency.value = '€'
        } else if (quotation.value.currency === 'USD') {
          currency.value = '$'
        } else if (quotation.value.currency === 'CNY') {
          currency.value = '¥'
        }
      }
    }
  }
}
quotationDetail()
// 查询订单
const orderEntity = ref({})
// 实际交货时间
const actualDeliveryTime = ref()
const getOrder = async () => {
  if (quotationId != null) {
    if (orderId === 'undefined') {
      orderId = 0
    }
    const result = await getOrderByQuotationId(quotationId, orderId)
    if (result?.code === 200) {
      orderEntity.value = result?.data
      order.value = result?.data
    }
    if (orderEntity?.value.actualDeliveryTime) {
      actualDeliveryTime.value = orderEntity.value.actualDeliveryTime
    }
    if (orderEntity?.value.orderType) {
      orderType2.value = orderEntity?.value.orderType
    }
  }
}
getOrder()

// 产品2和后面的产品
let productInfoList = reactive([])

const demandDetail = async () => {
  console.log('demandDetail in')
  const result = await getDemandDetail(requirementId)
  if (result?.code === 200) {
    demand.value = result?.data
    materialId.value = demand.value.material
    console.log(demand.value, 'demand.value')
    productInfoList = demand.value.productInfoList
    console.log(productInfoList, 'productInfoList')

    for (var item of demand.value?.clientImageList) {
      // console.log(item.imageUrl)
      // 如果后缀为pdf,就使用默认图片
      if (item.imageUrl.indexOf('.pdf') !== -1) {
        item.imageUrl = '	https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png'
      }
      imgList.value.push(item.imageUrl)
    }
    if (imgList.value.length > 0) {
      imgList.value = Array.from(new Set(imgList.value))
      currentImgSrc.value = imgList.value[0]
    }
    if (quotationId == null && requirementId != null && orderId == null) {
      if (demand.value?.client != null) {
        if (demand.value?.client.userId != null) {
          if (demand.value?.client.userId.toString() === getUserId()) {
            if (!demand.value?.supplierQuotationList || demand.value?.supplierQuotationList.length === 0) {
              showDeleteButton.value = true
            }
          }
        }
      }
    }
    // deliveryAddress.value = demand.value.clientDeliveryAddress.shippingAddress
  }
}

const quatationDetailId = reactive({
  id: 2356,
  orderType: 'sampleOrder',
  promptContent: 'Do you want to decline this quotation?'
})
// 使用 provide 函数向接收弹窗和查看碳值提供数据
provide('quatationDetailId', quatationDetailId)

demandDetail()

const changeImg = (img) => {
  currentImgSrc.value = img
  // 如果后缀为pdf,就使用默认图片
  if (img.indexOf('.pdf') !== -1) {
    currentImgSrc.value = '	https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png'
  }
}

// 产品详情是否展开
const isShowRequirementDetail = ref(true)
const showRequirementDetail = () => {
  isShowRequirementDetail.value = !isShowRequirementDetail.value
  console.log(isShowRequirementDetail.value)
}

// 查看碳值的弹窗
const showCarbonValue = ref(false)
// 打开查看碳值的弹窗
const openCarbonValue = () => {
  showCarbonValue.value = true
}
// 关闭查看碳值的弹窗
const closeCarbonValue = (data) => {
  if (data === 'close') {
    showCarbonValue.value = false
  }
}

// 发送问题的弹窗
const showContact = ref(false)
// 打开签大货单弹窗
const openContact = () => {
  console.log('发送问题')
  showContact.value = true
}
// 关闭签大货单的弹窗
const closeContact = (data) => {
  showContact.value = false
}

// 签单，填写样品数量的弹窗
const showSampleQuantity = ref(false)
// 打开填写样品数量弹窗
const openSampleQuantity = () => {
  console.log('签订单,询问是否需要样品')
  showSampleQuantity.value = true
}
// 关闭填写样品数量的弹窗
const closeSampleQuantity = (data) => {
  if (data === 'close') {
    showSampleQuantity.value = false
  } else {
    orderType.value = data
    showSampleQuantity.value = false
    quotationDetail()
    getOrder()
  }
}

// 不接受报价弹窗
const showRejectQuotation = ref(false)
// 打开不接受报价弹窗
const openRejectQuotation = () => {
  showRejectQuotation.value = true
  // quatationDetailId.type = 'reject'
}
// 关闭不接受报价的弹窗
const closeRejectQuotation = async (data) => {
  if (data === 'close') {
    showRejectQuotation.value = false
  }
  console.log(data, 'data')
  if (data === 'confirm') {
    // 确认拒绝这个报价
    const res = await allowOrRefuseQuo({ quotationId: quotationId, quotationStatus: 4, orderType: 0 })
    if (res.code === 200) {
      showRejectQuotation.value = false
      quotationDetail()
      getOrder()
    }
  }
}

// 跳转海运信息详情
const carbonTracking = () => {
  console.log('跳转海运信息详情')
  router.push({
    path: 'shipping',
    query: {
      orderId: orderEntity.value.orderId,
      containerNumber: orderEntity.value.orderTransportationInformation?.sealNumber
    }
  })
}

const deleteRequirementMethod = async () => {
  ElMessageBox.confirm(
    'This request will be permanently deleted and will not be restored.',
    'Delete',
    {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warning'
    }
  ).then(async () => {
    const res = await deleteRequirement(requirementId)
    if (res.code === 200) {
      router.go(-1)
    }
  })
    .catch(() => {

    })
}

</script>

<style lang="scss" scoped>
.container {
  background-color: #ffffff;
  line-height: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  &_progress {
      display: flex;
      flex-direction: column;
      padding: 30px;
      background-color: rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(5px);
      width: calc(100% - 60px);

      ::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &_top {
        display: flex;
        justify-content: space-between;
        z-index: 99;
        margin-bottom: 10px;

        &_left {
          text-align: left;
        }

        &_rifht {
          text-align: right;
        }

        &_title {
          font-size: 16px;
          font-weight: 700;
          color: rgba(24, 24, 24, 1);
          margin: 0;
          margin-bottom: 10px;
        }

        &_date {
          font-size: 12px;
          font-weight: 300;
          color: rgba(24, 24, 24, 0.7);
          margin: 10px 0 0 0 !important;
        }
      }

      &_bottom {
        overflow: auto;
        // margin-top: -30px;

        &_box {
          display: flex;

          &_card {
            display: flex;
            flex-direction: column;
            width: 300px;
            margin-right: 20px;
            flex-shrink: 0;

            &_line {
              margin-bottom: 30px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;

              &_round {
                width: 20px;
                height: 20px;
                border-radius: 50px;
                box-sizing: border-box;
                border: 2px solid rgba(39, 174, 96, 1);
                display: flex;
                justify-content: flex-end;
                align-items: center;
                z-index: 0;

                &::after {
                  flex-shrink: 0;
                  content: "";
                  display: block;
                  width: 325px;
                  height: 1px;
                  border-radius: 2px;
                  background-color: rgba(39, 174, 96, 0.1);
                }
              }

              // 货物当前地点样式
              &_roundActive {
                width: 20px;
                height: 20px;
                border: 2px solid rgba(39, 174, 96, 1);
                background-color: rgba(39, 174, 96, 1);

                &::after {
                  background-color: rgba(39, 174, 96, 1);
                }
              }

              // 货物已经过的地点样式
              &_roundAlready {
                width: 12px;
                height: 12px;
                // border: 3px solid rgba(39, 174, 96, 1);
                background-color: rgba(39, 174, 96, 1);

                &::after {
                  background-color: rgba(39, 174, 96, 1);
                }
              }

              // 货物未经过的地点样式
              &_roundNot {
                width: 20px;
                height: 20px;
                border: 2px solid rgba(173, 173, 173, 1);
                background-color: rgba(255, 255, 255, 0);

                &::after {
                  background-color: rgba(173, 173, 173, 0.1);
                }
              }
            }

            &_item {
              // height: 160px;
              background-color: rgb(255, 255, 255);
              border-radius: 6px;

              &_top {
                margin: 30px 0 20px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &_co2 {
                  display: flex;
                  font-size: 0;

                  img {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                  }

                  &_text {
                    display: flex;

                    &_title {
                      font-size: 16px;
                      font-weight: 700;
                      color: rgba(39, 174, 96, 1);
                      margin-right: 5px !important;
                    }

                    &_text {
                      font-size: 14px;
                      line-height: 16px;
                      font-weight: 300;
                      color: rgba(36, 89, 2, 0.5);

                      sub {
                        font-size: 12px;
                      }
                    }
                  }
                }

                &_lable {
                  font-size: 0;
                  margin-right: 20px;

                  img {
                    width: 16px;
                    height: 16px;
                  }
                }
              }

              &_status {
                font-size: 16px;
                line-height: 24px;
                color: rgba(24, 24, 24, 0.7);
                margin: 0 20px;
              }

              &_bottom {
                margin: 30px 20px;
                display: flex;
                justify-content: space-between;
                color: rgba(24, 24, 24, 0.7);
                font-size: 12px;
              }
            }

            &_itemActive {
              // height: 160px;
              background-color: rgba(39, 174, 96, 1);
              border-radius: 6px;

              &_top {
                margin: 30px 0 20px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &_co2 {
                  display: flex;
                  font-size: 0;

                  img {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                  }

                  &_text {
                    display: flex;

                    &_title {
                      font-size: 16px;
                      font-weight: 700;
                      color: rgba(255, 255, 255, 1);
                      margin-right: 5px !important;
                    }

                    &_text {
                      font-size: 14px;
                      line-height: 16px;
                      font-weight: 300;
                      color: rgba(255, 255, 255, 1);

                      sub {
                        font-size: 12px;
                      }
                    }
                  }
                }

                &_lable {
                  font-size: 0;
                  margin-right: 20px;

                  img {
                    width: 16px;
                    height: 16px;
                  }
                }
              }

              &_status {
                font-size: 16px;
                line-height: 24px;
                color: rgba(255, 255, 255, 1);
                margin: 0 20px;
              }

              &_bottom {
                margin: 30px 20px;
                display: flex;
                justify-content: space-between;
                color: rgba(255, 255, 255, 1);
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  &_content {
    width: 1600px;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;

    // 左侧图片
    &_left {
      width: 32%;
      font-size: 0;

      img {
        width: 500px;
        height: 500px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 6px;
      }

      &_imgs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        img {
          width: 80px;
          height: 80px;
          overflow: hidden;
          object-fit: cover;
          border-radius: 4px;
          margin-right: 15px;
          cursor: pointer;
          opacity: 0.3;

          &:nth-child(5n) {
            margin-right: 0px;
          }
        }

        &_isSelected {
          opacity: 1 !important;
          box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.5);
        }
      }

      &_carbon-tracking {
        width: 500px;
        height: 60px;
        font-size: 16px;
        line-height: 60px;
        font-weight: 700;
        color: rgba(12, 150, 69, 1);
        margin-top: 40px;
        background-image: url("../../assets/images/customer/carbonTracking_bg.png");
        background-size: cover;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 30px;
          height: 16px;
          object-fit: cover;
          margin-left: 20px;
        }
      }

      // 按钮
      &_btn,
      &_btn1 {
        width: 500px;
        height: 60px;
        background-color: #fff;
        border: 1px solid rgba(12, 150, 69, 1);
        font-size: 16px;
        font-weight: 600;
        color: rgba(12, 150, 69, 1);
        border-radius: 6px;
        margin-top: 15px;
        line-height: 60px;
        text-align: center;

        &:hover {
          background-color: rgba(12, 150, 69, 1);
          color: #fff;
          cursor: pointer;
        }
      }

      &_btn2 {
        width: 360px;
        height: 60px;
        background-color: #fff;
        border: 1px solid red;
        font-size: 16px;
        font-weight: 600;
        color: red;
        border-radius: 6px;
        margin-top: 15px;
        line-height: 60px;
        text-align: center;

        &:hover {
          background-color: red;
          color: #fff;
          cursor: pointer;
        }
      }

    }

    &_right {
      width: 66%;
      &_requirement {

        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        // margin-left: 410px;
        margin-bottom: 50px;

        // 标题
        &_titleBox {
          width: 100%;
          border: 1px solid rgba(255, 255, 255, 1);
          box-sizing: border-box;
          border-bottom: none;
          background: linear-gradient(180deg,
              rgba(39, 174, 96, 0.1) 0%,
              rgba(39, 174, 96, 0) 100%);
          display: flex;
          justify-content: space-between;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            margin: 40px 0 0 50px;
          }

          &_quantity {
            font-size: 16px;
            color: rgba(24, 24, 24, 1);
            font-weight: 600;
            margin: 40px 50px 20px 0;

            span {
              color: rgba(173, 173, 173, 1);
              font-weight: 300;
              margin-right: 10px;
            }
          }
        }

        // 产品详情
        &_details {
          // margin-top: -10px;
          background-image: url(../../assets/images/bg.svg);
          background-repeat: no-repeat;
          background-size: 500px 500px;
          background-position: center right;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            // border-top: 1px dashed rgba(39, 174, 96, .6);
            padding-top: 20px;
            margin: 20px 50px 0px 50px;
          }

          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &_title {
              font-size: 14px;
              color: rgba(150, 150, 150, 1);
              font-weight: 300;
              line-height: 16px;
              width: 200px;
            }

            &_value {
              font-size: 16px;
              color: rgba(24, 24, 24, 1);
            }

            &:last-child {
              // border: none;
            }
          }

          .file {
            display: flex;
            justify-content: space-between;

            &_left {
              display: flex;
              width: 500px;
            }

            &_download {
              font-size: 16px;
              color: rgba(39, 174, 96, 1);
              cursor: pointer;
            }
          }
        }

        // 通用数据
        .commonData {

          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &:first-child {
              border-top: 1px dashed rgba(39, 174, 96, .6) !important;
              margin-top: 50px !important;
            }
          }
        }

        // 折叠
        &_expand {
          padding: 0 50px 30px;

          &_box {
            font-size: 0;
            color: rgba(36, 89, 2, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 30px;
            border-top: 1px solid rgba(240, 240, 240, 1);
            cursor: pointer;

            span {
              font-size: 14px;
            }

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      // 需求详情
      .requirement {
        &_titleBox {
          background: #ffffff;
        }
      }

      // 报价详情
      .quotation {
        margin-bottom: 200px;

        .container_content_right_requirement_titleBox {
          display: flex;
          align-items: center;

          &_title {
            line-height: 50px;
            margin: 30px 0 30px 50px;
          }

          &_left {
            height: 50px;
            margin-right: 50px;
            border-radius: 6px;
            background-color: rgba(39, 174, 96, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;
            cursor: pointer;

            img {
              width: 24px;
              height: 24px;
              margin-right: 15px;

              &:last-child {
                width: 18px;
                height: 18px;
                margin-right: 15px;
              }
            }

            span {
              font-size: 28px;
              line-height: 30px;
              font-weight: 800;
              color: #ffffff;

              &+.co {
                font-size: 16px;
                color: rgba(255, 255, 255, 0.8);
                font-weight: 300;
                margin-left: 10px;
                margin-right: 20px;
              }
            }
          }
        }

        // 底部按钮
        .container_content_right_requirement_details_list_btn {
          display: flex;
          justify-content: center;
          padding: 60px 0;

          &_reject,
          &_accept {
            font-size: 14px;
            font-weight: 700;
            margin: 0 15px;
            padding: 10px 30px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }

          &_reject {
            color: rgba(255, 151, 0, 1);
            border: 1px solid rgba(255, 151, 0, 1);
            background-color: #fff;

            img {
              width: 25px;
              height: 25px;
              margin-right: 5px;
            }

            &:hover {
              // color: #fff;
              background-color: rgba(255, 151, 0, 0.1);
            }
          }

          &_accept {
            color: #fff;
            background-color: rgba(39, 174, 96, 1);

            &:hover {
              background-color: rgba(12, 150, 69, 1);
            }
          }
        }

        // 报价单状态
        .container_content_right_requirement_details_list_status {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 60px 0;

          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }

          span {
            font-size: 14px;
            font-weight: 700;
            color: rgba(39, 174, 96, 1);
            padding: 15px 0px;
          }

          .reject {
            color: rgba(255, 151, 0, 1);
          }
        }
      }

      &_btn {
        display: flex;
        margin-bottom: 60px;
        justify-content: center;

        .back_btn {
          font-size: 14px;
          font-weight: 700;
          padding: 20px 30px;
          margin: 0 25px;
          border-radius: 4px;
          cursor: pointer;
        }

        &_back {
          color: rgba(255, 255, 255, 1);
          background-color: rgba(39, 174, 96, 1);
        }
      }
    }
  }
}

.btn-back {
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: rgb(39, 174, 96);
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-back:hover {
  background-color: rgb(39, 174, 96);
}
</style>

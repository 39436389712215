import { render, staticRenderFns } from "./ShippingInformation.vue?vue&type=template&id=62f01181&scoped=true"
import script from "./ShippingInformation.vue?vue&type=script&setup=true&name=ShippingInformation&lang=js"
export * from "./ShippingInformation.vue?vue&type=script&setup=true&name=ShippingInformation&lang=js"
import style0 from "./ShippingInformation.vue?vue&type=style&index=0&id=62f01181&prod&lang=css"
import style1 from "./ShippingInformation.vue?vue&type=style&index=1&id=62f01181&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f01181",
  null
  
)

export default component.exports
import request from '@/utils/request'

export function login (data) {
  return request({
    url: '/api/user/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

export function register (data) {
  return request({
    url: '/api/user/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data
  })
}

export function getUserInfo (userId) {
  return request({
    url: '/api/user/getLoginUserInfo/' + userId,
    headers: {
      isToken: false
    },
    method: 'get'
  })
}

export function getLoginInfo () {
  return request({
    url: '/api/user/getLoginInfo',
    method: 'get'
  })
}

export function updatePassword (data) {
  return request({
    url: '/api/user/updatePassword',
    method: 'post',
    headers: {
      isToken: true
    },
    data
  })
}

export function updateUserBaseInfo (data) {
  return request({
    url: '/api/user/updateUserBaseInfo',
    method: 'post',
    data
  })
}

export function updateUserAvatar (data) {
  return request({
    url: '/api/user/updateUserAvatar',
    method: 'post',
    data
  })
}

export function getSysDictList (params) {
  return request({
    url: '/api/supplierProduct/getSysDictList',
    method: 'get',
    params
  })
}

export function getMaterialList () {
  return request({
    url: '/api/clientRequirement/getMaterialList',
    method: 'get'
  })
}

export function getMaterialListByCatalog (params) {
  return request({
    url: '/api/clientRequirement/getMaterialListByCatalog',
    method: 'get',
    params
  })
}


export function delImage (id) {
  return request({
    url: '/api/user/delImage/' + id,
    method: 'delete'
  })
}

export function addImages (data) {
  return request({
    url: '/api/user/addImages',
    method: 'post',
    data
  })
}

import { createStore } from 'vuex'
import { getToken, removeToken, getStationId, setStationId } from '@/utils/auth'
export default createStore({
  state: {
    token: getToken(),
    name: '',
    stationId: getStationId(),
    closed: false
  },
  mutations: {
    setStationIds (state, stationId) {
      state.stationId = stationId
      setStationId(stationId)
    }
  },
  getters: {
    getClosed: state => state.closed,
    getMessages: state => state.messages,
    getOnline: state => state.online,
    getStation: state => state.stationId
  },
  actions: {
    // 退出系统
    LogOut ({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          commit('SET_TOKEN', '')
          removeToken()
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    }
  },
  modules: {
  }
})

<template>
    <div class="container">
        <div class="container_box">
            <ComponentSignUp @changeShow="changeLoginShow" :type="type" :step="step" @toSignUpPage="toSignUp" v-if="loginShow===false" />
            <ComponentLogin @changeShow="changeLoginShow" @toLoginPage="toLogin" v-if="loginShow===true" />
        </div>
    </div>
</template>

<script setup name="Login">
import ComponentLogin from '../../components/Login/ComponentLogin.vue'
import ComponentSignUp from '../../components/Login/ComponentSignUp.vue'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getToken } from '@/utils/auth'
import { getLoginInfo } from '@/api/user'

const router = useRouter()
const userType = ref('1')
const checkLogin = async () => {
  const token = getToken()
  if (token != null) {
    const res = await getLoginInfo()
    if (res.code === 200) {
      userType.value = res.data.registerStep?.type
      if (userType.value == '2') {
        router.push({
          path: 'home'
        })
      } else {
        router.push({
          path: 'supplierHome'
        })
      }
    }
  } else {
    router.push({
      path: '/'
    })
  }
}
checkLogin()
const loginShow = ref(true)
const result = ref({})
const route = useRoute()
const changeLoginShow = (val) => {
  loginShow.value = val
}
const step = ref('1')
const type = ref('0')

const toLogin = (data) => {
  console.log('----------->' + data)
  result.value = data
  let a = 0

  for (const e of result.value) {
    if (e.name === 'step' && e.value === '2') {
      a = 1
    }
    if (e.name === 'type') {
      type.value = e.value
    }
  }
  if (a === 1) {
    step.value = '3'
    loginShow.value = false
  } else {
    loginShow.value = true
  }
}
const toSignUp = (data) => {
  if (data === 'signUp') {
    loginShow.value = false
  } else if (data === 'login') {
    loginShow.value = true
  }
}

// 获取路由参数,跳转注册

</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100vh;
    background: url("../../assets/images/login/login_bg.png") no-repeat;
    background-size: cover;
    text-align: left;
    line-height: 1;
    overflow-y: auto;

    ::-webkit-scrollbar {
        display: none;
    }

    // 在其他浏览器隐藏滚动条
    -ms-overflow-style: none;

}
</style>

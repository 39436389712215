import request from '@/utils/request'

export function queryDemandList (params) {
  return request({
    url: '/api/clientRequirement/list',
    method: 'get',
    params
  })
}
export function addDemand (data) {
  return request({
    url: '/api/clientRequirement/add',
    method: 'post',
    data
  })
}
export function getPendingQuotedList (params) {
  return request({
    url: '/api/supplier/getPendingQuotedList',
    method: 'get',
    params
  })
}

export function getQuotedList (params) {
  return request({
    url: '/api/supplier/getQuotedList',
    method: 'get',
    params
  })
}
export function getDemandDetail (requirementId) {
  return request({
    url: '/api/clientRequirement/' + requirementId,
    method: 'get'
  })
}

export function deleteRequirement (requirementId) {
  return request({
    url: '/api/clientRequirement/deleteRequirement/' + requirementId,
    method: 'delete'
  })
}

export function getClientArtByRequirementId (requirementId) {
  return request({
    url: '/api/clientRequirement/getClientArtByRequirementId/' + requirementId,
    method: 'get'
  })
}


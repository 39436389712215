import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/home',
    name: 'Home',
    meta: { navShow: true, keepAlive: true, title: 'index', isleftarrow: false },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/componentSignUp',
    name: 'ComponentSignUp',
    meta: { navShow: true, keepAlive: true, title: 'index', isleftarrow: false },
    component: () => import('../components/Login/ComponentSignUp.vue')
  },
  {
    path: '/productDetail',
    name: 'ProductDetail',
    meta: { navShow: true, keepAlive: true, title: 'productDetail', isleftarrow: false },
    component: () => import('../views/product/ProductDetail.vue')
  },
  {
    path: '/sendCustomizeRequest',
    name: 'SendCustomizeRequest',
    meta: { navShow: true, keepAlive: true, title: 'sendCustomizeRequest', isleftarrow: false },
    component: () => import('../views/product/SendCustomizeRequest.vue')
  },
  {
    path: '/quotationDetail',
    name: 'QuotationDetail',
    meta: { navShow: true, keepAlive: true, title: 'quotationDetail', isleftarrow: false },
    component: () => import('../views/customer/QuotationDetail.vue')
  },
  {
    path: '/',
    name: 'Login',
    meta: { navShow: false, keepAlive: true, title: 'login', isleftarrow: false },
    component: () => import(/* webpackChunkName: "home" */ '../views/login/Login.vue')
  },
  {
    path: '/supplierHome',
    name: 'supplierHome',
    meta: { navShow: true, keepAlive: false, title: 'supplierHome', isleftarrow: false },
    component: () => import('../views/supplier/SupplierHome.vue')
  },
  {
    path: '/requirementDetails',
    name: 'requirementDetails',
    meta: { navShow: true, keepAlive: false, title: 'requirementDetails', isleftarrow: false },
    component: () => import('../views/supplier/RequirementDetails.vue')
  },
  {
    path: '/customerCenter',
    name: 'CustomerCenter',
    meta: { navShow: true, keepAlive: false, title: 'CustomerCenter', isleftarrow: false },
    component: () => import('../views/customer/CustomerCenter.vue')
  },
  {
    path: '/userCenter',
    name: 'SupplierCenter',
    meta: { navShow: true, keepAlive: false, title: 'supplierCenter', isleftarrow: false },
    component: () => import('../views/supplier/SupplierCenter.vue')
  },
  {
    path: '/quotation',
    name: 'quotation',
    meta: { navShow: true, keepAlive: false, title: 'quotation', isleftarrow: false },
    component: () => import('../views/supplier/Quotation.vue')
  },
  {
    path: '/shipping',
    name: 'ShippingInformation',
    meta: { navShow: true, keepAlive: false, title: 'Customer', isleftarrow: false },
    component: () => import('../views/customer/ShippingInformation.vue')
  },
  {
    path: '/quotationDetail',
    name: 'quotationDetail',
    meta: { navShow: true, keepAlive: false, title: 'quotationDetail', isleftarrow: false },
    component: () => import('../views/customer/QuotationDetail.vue')
  },
  {
    path: '/quotationDetails',
    name: 'quotationDetails',
    meta: { navShow: true, keepAlive: false, title: 'quotationDetails', isleftarrow: false },
    component: () => import('../views/customer/QuotationDetails.vue')
  },
  {
    path: '/quotationDetailFormalOrder',
    name: 'QuotationDetailFormalOrder',
    meta: { navShow: true, keepAlive: false, title: 'QuotationDetailFormalOrder', isleftarrow: false },
    component: () => import('../views/customer/QuotationDetailFormalOrder.vue')
    // 暂时不用 报价单详情 - 大货单 和 quotationDetail基本一样的，除了下面的按钮显示的accept reject ，可以在quotationDetail页面通过订单类型判断按钮的显示隐藏
  },
  {
    path: '/sampleOrderDetail',
    name: 'SampleOrderDetail',
    meta: { navShow: true, keepAlive: false, title: 'SampleOrderDetail', isleftarrow: false },
    component: () => import('../views/customer/SampleOrderDetail.vue')
    // 暂时不用 样品单详情 - 主页 Sample Request 类名有sanmple但里面按钮是sign formal order，整体页面还是quotationDetail 就下面的按钮换了
  },
  {
    path: '/signFormalOrder',
    name: 'SignFormalOrder',
    meta: { navShow: true, keepAlive: false, title: 'SignFormalOrder', isleftarrow: false },
    component: () => import('../views/customer/SignFormalOrder.vue')
    // signFormalOrder 签正式订单 样品单列表 signFormalOrder点击进去的 表单页
  },
  {
    path: '/officialOrderDetail',
    name: 'OfficialOrderDetail',
    meta: { navShow: true, keepAlive: false, title: 'OfficialOrderDetail', isleftarrow: false },
    component: () => import('../views/customer/OfficialOrderDetail.vue')
    // 已完成的订单详情
  },
  {
    path: '/requirementDetailsCustomer',
    name: 'requirementDetailsCustomer',
    meta: { navShow: true, keepAlive: false, title: 'requirementDetailsCustomer', isleftarrow: false },
    component: () => import('../views/customer/RequirementDetailsCustomer.vue')
    // 暂时不用 客户看的需求详情 这里面没有quotation 列表，与quotationDetail 页面重合 ，在与quotationDetail页面判断是否有quotation进行显示隐藏就可以
  },
  {
    path: '/orderDetail',
    name: 'OrderDetail',
    meta: { navShow: true, keepAlive: false, title: 'orderDetail', isleftarrow: false },
    component: () => import('../views/supplier/OrderDetail.vue')
    // 供应商 - 订单详情
  },
  {
    path: '/editProduct',
    name: 'EditProduct',
    meta: { navShow: true, keepAlive: false, title: 'editProduct', isleftarrow: false },
    component: () => import('../components/userCenter/EditProduct.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 跳转页面时，保持页面在顶部
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0

  // 兼容IE
  window.scrollTo(0, 0)
  next()
})

export default router

// SupplierHome                     客户主页
// RequirementDetails               需求详情
// customer                         个人中心
// CarbonFootprintTracking          碳足迹追踪
// Quotation                        报价
// QuotationDetail                  报价详情,样品单
// QuotationDetailFormalOrder       报价详情,正式订单
// SampleOrderDetail                样品单详情,已经进行中的
// SignFormalOrder                  签订正式订单
// OfficialOrderDetail              正式订单详情

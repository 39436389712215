<template>
  <div class="customer-information">
    <div class="customer-information_company">
      <div class="customer-information_company_left">
        <div class="customer-information_company_left_img"><img :src="avatar" alt="">
        </div>
        <div class="customer-information_company_left_title">{{ forms.clientName }}</div>
      </div>
      <div class="customer-information_company_right">
        <div class="customer-information_company_right_btn" @click="updateAvatar">
          Change Profile Picture
        </div>
      </div>
    </div>

    <!-- 用户信息表单 -->
    <div class="customer-information_form">
      <div class="customer-information_form_title">
        Basic Information
      </div>
      <div class="customer-information_form_body">
        <el-form :model="forms" :rules="rules">
          <el-form-item label="Company Name" prop="corporateName">
            <el-input v-model="forms.corporateName" placeholder="Enter Company Name"></el-input>
          </el-form-item>
          <el-form-item label="Position" prop="duties">
            <el-input v-model="forms.duties" placeholder="Enter Position"></el-input>
          </el-form-item>
          <el-form-item label="Company Address" prop="companyAddress">
            <el-input v-model="forms.companyAddress" placeholder="Enter Company Address"></el-input>
          </el-form-item>
          <el-form-item label="Landline" prop="companySwitchboard">
            <el-input v-model="forms.companySwitchboard" placeholder="Enter Landline"></el-input>
          </el-form-item>
          <el-form-item label="Contact Name" prop="contacts">
            <el-input v-model="forms.contacts" placeholder="Enter Contact Name"></el-input>
          </el-form-item>
          <el-form-item label="Contact Email" prop="contactEmail">
            <el-input v-model="forms.contactEmail" placeholder="Enter Company Email"></el-input>
          </el-form-item>
          <el-form-item label="Contact Phone" prop="contactPhone">
            <el-input v-model="forms.contactPhone" placeholder="Enter Phone"></el-input>
          </el-form-item>
          <el-form-item label="Primary Delivery Address" prop="defaultReceivingAddress">
            <el-input v-model="forms.defaultReceivingAddress" placeholder="Enter Primary Delivery Address"></el-input>
          </el-form-item>
          <el-form-item label="CRO No." prop="croNo">
            <el-input v-model="forms.croNo" placeholder="Enter CRO No."></el-input>
          </el-form-item>
          <el-form-item label="EORI No (VAT NO)" prop="eoriNo">
            <el-input v-model="forms.eoriNo" placeholder="Enter EORI No."></el-input>
          </el-form-item>

        </el-form>
        <div class="customer-information_form_body_subbtn" @click="updateUserInfo">
          Save
        </div>
      </div>

    </div>

    <!--      换头像弹窗-->
    <el-dialog v-model="dialogVisible" title="Change Profile Picture">
      <el-upload class="avatar-uploader" :action="uploadFileUrl" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeUpload" :headers="headers" multiple>
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <el-icon v-else class="avatar-uploader-icon">
          <Plus />
        </el-icon>
      </el-upload>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelAvatar">Cancel</el-button>
          <el-button type="primary" @click="submitAvatar">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script setup name="CustomerInformation">
import { reactive, ref } from 'vue'
import { getLoginInfo, getUserInfo, updateUserBaseInfo, updateUserAvatar } from '@/api/user'
import { setToken, getToken } from '@/utils/auth'

import { target } from '@/utils/URL'
import { ElMessage } from 'element-plus'
const avatar = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/head.png')
const imageUrl = ref('')
const uploadFileUrl = ref(target + 'common/upload') // 上传的图片服务器地址
const headers = ref({
  Authorization: getToken()
})
const dialogVisible = ref(false)
const forms = ref({
  duties: '',
  corporateName: '',
  companyAddress: '',
  companySwitchboard: '',
  contacts: '',
  defaultReceivingAddress: '',
  contactEmail: '',
  contactPhone: '',
  croNo: '',
  eoriNo: ''
})

const rules = ref({
  corporateName: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  duties: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companyAddress: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companySwitchboard: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contacts: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactEmail: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactPhone: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  defaultReceivingAddress: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  croNo: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  eoriNo: [
    { required: true, message: 'Required', trigger: 'blur' }
  ]
})
const getUser = async () => {
  const res = await getLoginInfo()
  if (res.code === 200) {
    forms.value = res.data.client
    forms.value.corporateName = res.data.client?.clientCompanyName
    console.log('forms.value.corporateName:' + forms.value.corporateName)
    forms.value.companyAddress = res.data.client?.clientAddress
    forms.value.companySwitchboard = res.data.client?.clientCompanySwitchboard
    forms.value.contacts = res.data.client?.clientName
    forms.value.defaultReceivingAddress = res.data.client?.defaultReceivingAddress
    forms.value.contactEmail = res.data.client?.clientMailbox
    forms.value.contactPhone = res.data.client?.clientContactNumber
    forms.value.croNo = res.data.client?.croNo
    forms.value.eoriNo = res.data.client?.eoriNoVatNo
    if (res.data.sysUser?.avatar != null && res.data.sysUser?.avatar !== '') {
      avatar.value = res.data.sysUser.avatar
    }
  }
}
getUser()
const updateUserInfo = () => {
  forms.value.type = '2'
  const res = updateUserBaseInfo(forms.value)
  if (res.code === 200) {

  }
}

const beforeUpload = (file) => {
  // const isLt2M = file.size / 1024 / 1024 < 2
  // if (!isLt2M) {
  //   ElMessage.error('上传头像图片大小不能超过 2MB!')
  // }
  // return isLt2M
  return true
}

const handleAvatarSuccess = (response, uploadFile, uploadFiles) => {
  imageUrl.value = response.url
}

// 打开换头像
const updateAvatar = () => {
  imageUrl.value = avatar.value
  dialogVisible.value = true
}
// 换头像取消
const cancelAvatar = () => {
  dialogVisible.value = false
  imageUrl.value = null
}

const submitAvatar = async () => {
  const data = {
    avatar: imageUrl.value
  }
  const res = await updateUserAvatar(data)
  if (res.code === 200) {
    dialogVisible.value = false
    avatar.value = imageUrl.value
  }
}

</script>

<style lang="scss" scoped>
@import '../../assets/scss/elForm.scss';

.customer-information {
  padding: 30px;

  &_company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(248, 248, 248, 0.8);
    padding: 25px 30px;
    border-radius: 8px;

    &_left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &_img {
        img {
          width: 60px;
          height: 60px;
        }
      }

      &_title {
        font-size: 16px;
        color: #181818;
        margin-left: 20px;
      }
    }

    &_right {
      &_btn {
        background-color: rgba(39, 174, 96, 0.04);
        padding: 15px 30px;
        color: #27ae60;
        border: 1px solid #27ae60;
        font-size: 14px;
        border-radius: 6px;
      }
    }
  }

  &_form {
    margin-top: 50px;

    &_title {
      font-size: 16px;
      color: #181818;
      font-weight: 700;
    }

    &_body {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &_subbtn {
        background-color: rgba(39, 174, 96, 0.04);
        padding: 15px 30px;
        color: #27ae60;
        border: 1px solid #27ae60;
        font-size: 14px;
        border-radius: 6px;
        width: 85px;
        text-align: center;
        margin: 50px auto 30px;
      }

    }
  }
}
</style>

<style scoped>
.avatar-uploader {
  text-align: center;
}

.avatar-uploader .avatar {
  width: 278px;
  height: 278px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>

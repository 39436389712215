<!-- 报价单详情 - 样品单 -->
<template>
  <div class="container">
    <div class="container_content">
      <!-- 左侧图片 -->
      <div class="container_content_left">
        <img
          :src="currentImgSrc"
          alt=""
        >
        <div class="container_content_left_imgs">
          <img
            v-for="(img, index) in imgList"
            :key="index"
            :src="img"
            @click="changeImg(img)"
            :class="{ 'container_content_left_imgs_isSelected': img === currentImgSrc }"
            alt=""
          >
        </div>
        <div
          class="container_content_left_carbon-tracking"
          @click="carbonTracking"
          v-if="orderEntity != null && quotationId != null"
        >
          <span>Carbon Tracking</span>
          <img
            src="../../assets/images/home/toRight_green.png"
            alt=""
          >
        </div>
        <!--        <div class="container_content_left_btn">-->
        <!--          Sample delivery-->
        <!--        </div>-->
        <div
          class="container_content_left_btn1"
          @click="$router.go(-1)"
        >
          Back
        </div>
        <div
          class="container_content_left_btn2"
          v-if="showDeleteButton"
          @click="deleteRequirementMethod"
        >
          Delete
        </div>
      </div>

      <!-- 右侧 -->
      <div class="container_content_right">

        <!-- 需求详情  requirement-->
        <div class="container_content_right_requirement requirement">

          <!-- 标题 -->
          <div class="container_content_right_requirement_titleBox requirement_titleBox">
            <div class="container_content_right_requirement_titleBox_title">{{ demand.productName }}</div>
            <div class="container_content_right_requirement_titleBox_order">{{ orderStatus }}</div>
            <!-- <div class="container_content_right_requirement_titleBox_quantity"><span>Quantity</span>{{ demand.quantity
            }}
            </div> -->
          </div>

          <!-- 详情 -->
          <div
            class="container_content_right_requirement_details"
            v-show="isShowRequirementDetail"
          >
            <div
              class="container_content_right_requirement_details_list"
              v-for="(item, index) in keys"
              :key="item.key"
            >
              <div class="container_content_right_requirement_details_list_title">{{ getTitle(item) }}</div>
              <div class="container_content_right_requirement_details_list_value">{{ (getDisplayedValue(item)===""||getDisplayedValue(item)==null)?"N/A":getDisplayedValue(item) }}</div>
            </div>
            <!--						<div class="container_content_right_requirement_details_list file">-->
            <!--							<div class="file_left">-->
            <!--								<div class="container_content_right_requirement_details_list_title">Original Artwork-->
            <!--								</div>-->
            <!--								<div class="container_content_right_requirement_details_list_value">Design draft.ai-->
            <!--								</div>-->
            <!--							</div>-->
            <!--							<div class="file_download">Download</div>-->
            <!--						</div>-->
          </div>

          <!-- 产品2和后面的产品 -->
          <div
            class="container_content_right_requirement_details"
            v-for="(product, productIndex) in productInfoList"
            :key="productIndex"
          >
            <div class="container_content_right_requirement_details_title">
              {{ product.productName }}
            </div>
            <div
              class="container_content_right_requirement_details_list"
              v-for="(item, index) in keys"
              :key="item.key"
            >
              <div class="container_content_right_requirement_details_list_title">{{ getTitle(item) }}</div>
              <div class="container_content_right_requirement_details_list_value">{{ (getDisplayedValue2(productIndex, item)===""||getDisplayedValue2(productIndex, item)===null)?"N/A":getDisplayedValue2(productIndex, item) }}</div>
            </div>
          </div>

          <!-- 需求单通用数据 -->
          <div class="container_content_right_requirement_details commonData">
            <div
              class="container_content_right_requirement_details_list commonData_list"
              v-for="(item, index) in commonData"
              :key="item.key"
            >
              <div class="container_content_right_requirement_details_list_title">{{ getCommonTitle(item) }}</div>
              <div class="container_content_right_requirement_details_list_value">{{ (getDisplayedValue(item)===""||getDisplayedValue(item)==null)?"N/A":getDisplayedValue(item) }}</div>
            </div>
          </div>

          <!-- Expand/Collapse -->
          <!-- <div class="container_content_right_requirement_expand" @click="showRequirementDetail">
            <div class="container_content_right_requirement_expand_box">
              <span>Expand</span>
              <img src="../../assets/images/customer/Expand.png" alt="">
            </div>
          </div> -->

          <!--          <div>-->
          <!--            <router-link class="btn btn-back" to="javascript:void(0);" @click="$router.go(-1)">-->
          <!--              <i class="fa fa-arrow-left"></i> Back-->
          <!--            </router-link>-->
          <!--          </div>-->
        </div>
      </div>

    </div>
    <!-- 报价单  quotation-->
    <div
      class="container_main"
      v-if="qutationList.length>0"
    >
      <div class="container_main_top">
        <div class="container_main_top_left">
          <div class="container_main_top_left_info">
            <div class="container_main_top_left_title">Choose The Quotation</div>
            <div class="container_main_top_left_desc">choose the best quotation for you</div>

          </div>

          <div class="container_main_top_left_table">
            <div class="container_main_top_left_table_titleline">
              Pricing
            </div>
            <div
              class="container_main_top_left_table_line"
              style="margin-top:20px"
            >
              Price
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Unit Price
            </div>
            <el-divider />
            <div
              class="container_main_top_left_table_line"
              style="margin-bottom:20px;"
            >
              <div style="color: orange">
                <span @click="openCarbonValue">Co2</span>
              </div>
            </div>

            <div class="container_main_top_left_table_titleline">
              Featured
            </div>
            <div
              class="container_main_top_left_table_line"
              style="margin-top:20px"
            >
              Material
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Material Usage
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Sample Charge
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Sample Lead Time
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Pcs/Ctn
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Carton Size (mm)
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Weight/Carton (kg)
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Total CBM (m<sup>3</sup>)
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Gross Weight (kg)
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              FOB/CIF (unit)
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Special Charges
            </div>
            <el-divider />
            <div class="container_main_top_left_table_line">
              Charge Amount
            </div>

          </div>
        </div>
        <div class="container_main_top_right">
          <div
            class="container_main_top_right_item"
            v-for="quotation in qutationList"
            :key="quotation.quotationId"
          >

            <div class="container_main_top_right_info">
              <div
                class="container_main_top_right_item_title"
                v-if="quotation.supplier?.supplierName.length<=20"
              >
                {{ quotation.supplier?.supplierName ?? "Anonymous"}}
              </div>

              <div
                class="container_main_top_right_item_title"
                v-else
              >
                {{ quotation.supplier?.supplierName.substring(0,21)}}
              </div>
              <div class="container_main_top_right_item_img">
                <img src="../../assets/images/company.png" />
              </div>
              <div class="container_main_top_right_item_status">

                <!-- 不接受报价和接受报价按钮 -->
                <div
                  class="container_main_top_right_item_status_btn"
                  v-show="quotation.status !== 3 && quotation.status !== 4 && orderType === ''"
                >

                  <div
                    class="container_main_top_right_item_status_btn_reject"
                    @click="openContact"
                  >
                    <img
                      src="../../assets/images/customer/contact.png"
                      alt=""
                    >
                    Contact
                  </div>
                  <div
                    class="container_main_top_right_item_status_btn_reject"
                    @click="openRejectQuotation"
                  >
                    Decline
                  </div>
                  <div
                    class="container_main_top_right_item_status_btn_accept"
                    @click="openSampleQuantity"
                  >
                    Accept
                  </div>
                </div>

                <!-- 已签大货单，不需要样品 -->
                <div
                  class="container_main_top_right_item_status_btn"
                  v-show="orderType === 2"
                >
                  <img
                    src="../../assets/images/supplier/check-circle.png"
                    alt=""
                  >
                  <span>Quotation accepted,awaitng mass production.</span>
                </div>

                <div
                  class="container_main_top_right_item_status_btn"
                  v-show="quotation.status === 3 && orderType !== 2"
                >
                  <img
                    src="../../assets/images/supplier/check-circle.png"
                    alt=""
                  >
                  <span>Quote Accepted, waiting for sample shipment</span>
                </div>

                <!-- 已拒绝 -->
                <div
                  class="container_main_top_right_item_status_btn"
                  v-show="quotation.status === 4"
                >
                  <img
                    src="../../assets/images/supplier/emotion-unhappy.png"
                    alt=""
                  >
                  <span class="reject">this quotataion would be declined,you might net able to restore it.</span>
                </div>
                <!--                <div class="container_main_top_right_item_status_btn">
                  Get started
                </div>-->
              </div>

            </div>

            <div class="container_main_top_right_item_heandline"></div>
            <div class="container_main_top_right_table">
              <div
                class="container_main_top_right_table_item"
                style="margin-top:20px"
              >
                {{ currency }} {{ thousand(quotation.totalprice) }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ currency }} {{ thousand(quotation.unitPrice) }}
              </div>
              <el-divider />
              <div
                class="container_main_top_right_table_item"
                style="margin-bottom:20px"
              >
                {{ thousands(quotation.totalco) }}kg
              </div>
              <div class="container_main_top_right_item_heandline"></div>
              <div
                class="container_main_top_right_table_item"
                style="margin-top:20px"
              >
                <!--                {{ quotation.ekoTpfsjChina?.catalog2==null?'N/A':quotation.ekoTpfsjChina?.catalog2}}-->
                {{quotation.materialStr}}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.materialUsageStr }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.sampleUnitPrice == null ? "N/A" : currency }} {{ quotation.sampleUnitPrice}}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.sampleGenerationPeriod==null?'N/A':quotation.sampleGenerationPeriod }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.pcsCtnSingle }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.ctnSizeSingle }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.weightCtnSingle }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.totalCbm==null?'N/A':quotation.totalCbm }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.totalGrossWeight }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.fobcif }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ (quotation.specialCharges== null||quotation.specialCharges== '') ? "N/A" : quotation.specialCharges  }}
              </div>
              <el-divider />
              <div class="container_main_top_right_table_item">
                {{ quotation.chargeAmount == null ? "N/A" : currency }} {{ quotation.chargeAmount }}
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

    <div
      class="container_main"
      v-if="qutationList.length>0"
    >
      <!-- <div class="container_main_title">
        <div
          :class="[quotation.checked===true ? 'container_main_title_info' : 'container_main_title_infounselect']"
          style="margin-left: 10px"
          v-for="quotation in qutationList"
          :key="quotation.quotationId"
          @click="changeQuotation(quotation.quotationId)"
        >
          Quotation of {{ quotation.supplier?.supplierName }}
        </div>
      </div> -->

      <!--      <div class="container_main_right">
        <div class="container_main_right_requirement quotation">
          &lt;!&ndash; 标题 &ndash;&gt;
          <div class="container_main_right_requirement_titleBox">
            <div class="container_main_right_requirement_titleBox_title">Quotation</div>
            <div
              class="container_main_right_requirement_titleBox_left"
              @click="openCarbonValue"
            >
              <img
                src="../../assets/images/home/carbonFootprint_white.png"
                alt=""
              >
              <span>{{ thousands(quotation.totalco) }}kg</span>
              <span class="co">CO<sub>2</sub> </span>
              <img
                src="../../assets/images/customer/white_right.png"
                alt=""
              >
            </div>
          </div>
          &lt;!&ndash; 详情 &ndash;&gt;
          <div class="container_main_right_requirement_details">
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Company Name</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.supplier?.supplierName }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">HS code</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.hscode }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Estimated Time of Delivery</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.productionDuration==null?'N/A':quotation.productionDuration }}</div>
            </div>
            <div
              class="container_main_right_requirement_details_list"
              v-if="userType === '2' && orderType2 === 2"
            >
              <div class="container_main_right_requirement_details_list_title">Actual Delivery Time</div>
              <div class="container_main_right_requirement_details_list_value">{{ actualDeliveryTime==null?'N/A':actualDeliveryTime }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Sample Charge</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.sampleUnitPrice == null ? "" : currency }} {{ quotation.sampleUnitPrice}}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Sample Lead Time</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.sampleGenerationPeriod==null?'N/A':quotation.sampleGenerationPeriod }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Pcs/Ctn</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.pcsCtnSingle }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Carton Size (mm)</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.ctnSizeSingle }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Weight/Carton (kg)</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.weightCtnSingle }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Total CBM (m<sup>3</sup>)</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.totalCbm==null?'N/A':quotation.totalCbm }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Gross Weight (kg)</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.totalGrossWeight }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">FOB/CIF (unit)</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.fobcif }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Special Charges</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.specialCharges }}</div>
            </div>
            <div
              class="container_main_right_requirement_details_list"
              v-if="quotation.specialCharges !== 'None'"
            >
              <div class="container_main_right_requirement_details_list_title">Charge Amount</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.chargeAmount == null ? "" : currency }} {{ quotation.chargeAmount }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Material</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.materialStr}}
              </div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Material Usage</div>
              <div class="container_main_right_requirement_details_list_value">{{ quotation.materialUsageStr }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Unit Price</div>
              <div class="container_main_right_requirement_details_list_value">{{ currency }} {{ thousand(quotation.unitPrice) }}</div>
            </div>
            <div class="container_main_right_requirement_details_list">
              <div class="container_main_right_requirement_details_list_title">Price</div>
              <div class="container_main_right_requirement_details_list_value">{{ currency }} {{ thousand(quotation.totalprice) }}</div>
            </div>
            &lt;!&ndash; 不接受报价和接受报价按钮 &ndash;&gt;
            <div
              class="container_main_right_requirement_details_list_btn"
              v-show="quotation.status !== 3 && quotation.status !== 4 && orderType === ''"
            >

              <div
                class="container_main_right_requirement_details_list_btn_reject"
                @click="openContact"
              >
                <img
                  src="../../assets/images/customer/contact.png"
                  alt=""
                >
                Contact
              </div>
              <div
                class="container_main_right_requirement_details_list_btn_reject"
                @click="openRejectQuotation"
              >
                Decline
              </div>
              <div
                class="container_main_right_requirement_details_list_btn_accept"
                @click="openSampleQuantity"
              >
                Accept
              </div>
            </div>

            &lt;!&ndash; 已签大货单，不需要样品 &ndash;&gt;
            <div
              class="container_main_right_requirement_details_list_status"
              v-show="orderType === 2"
            >
              <img
                src="../../assets/images/supplier/check-circle.png"
                alt=""
              >
              <span>Quotation accepted,awaitng mass production.</span>
            </div>

            &lt;!&ndash; 已签大货单，申请了样品 &ndash;&gt;
            &lt;!&ndash; <div
      class="container_content_right_requirement_details_list_status"
      v-show="orderEntity?.orderType === 3"
    >
      <img
        src="../../assets/images/supplier/check-circle.png"
        alt=""
      >
      <span>Quote Accepted, waiting for sample shipment</span>
    </div> &ndash;&gt;
            <div
              class="container_main_right_requirement_details_list_status"
              v-show="quotation.status === 3 && orderType !== 2"
            >
              <img
                src="../../assets/images/supplier/check-circle.png"
                alt=""
              >
              <span>Quote Accepted, waiting for sample shipment</span>
            </div>

            &lt;!&ndash; 已拒绝 &ndash;&gt;
            <div
              class="container_main_right_requirement_details_list_status"
              v-show="quotation.status === 4"
            >
              <img
                src="../../assets/images/supplier/emotion-unhappy.png"
                alt=""
              >
              <span class="reject">this quotataion would be declined,you might net able to restore it.</span>
            </div>

            &lt;!&ndash; 只样品单 &ndash;&gt;
            &lt;!&ndash; <div
      class="container_content_right_requirement_details_list_status"
      v-show="orderType === 1"
    >
      <img
        src="../../assets/images/supplier/check-circle.png"
        alt=""
      >
      <span>Quote Accepted, waiting for sample shipment</span>
    </div> &ndash;&gt;
          </div>

        </div>

      </div>-->

    </div>

    <!-- 查看碳值的弹窗 -->
    <ViewCarbonValue
      v-if="quotation.quotationId"
      v-on:customEvent="closeCarbonValue"
      v-show="showCarbonValue"
      :quotation="quotation"
    />

    <!-- 联系，发送内容的弹窗 -->
    <ContactPopup
      v-on:customEvent="closeContact"
      v-show="showContact"
      :info="info"
    />

    <!-- 签单，填写样品数量的弹窗 -->
    <SignSampleOrderPopup
      v-on:customEvent="closeSampleQuantity"
      v-show="showSampleQuantity"
      :quotationId="quotationId"
    />

    <!-- 不接受报价的弹窗 -->
    <RejectQuotation
      v-on:customEvent="closeRejectQuotation"
      v-show="showRejectQuotation"
      :detael="quatationDetailId"
    />

    <!-- foot   -->
    <Foot />
  </div>
</template>

<!-- 报价单详情 -->
<script setup name="QuotationDetail">
import { ref, reactive, provide, computed } from 'vue'
import Foot from '../../components/Foot.vue'

// 碳值弹窗
import ViewCarbonValue from './dialog/ViewCarbonValue.vue'

// 签大货单，询问是否需要样品的弹窗
import ContactPopup from './dialog/ContactPopup.vue'

// 签样品单，填写样品数量
import SignSampleOrderPopup from './dialog/SignSampleOrderPopup.vue'

// 不接受报价的弹窗
import RejectQuotation from './dialog/OperationTips.vue'
import { getQuotationDetail, getQuotationsByRequirementId } from '@/api/quotation'
import { getDemandDetail, deleteRequirement } from '@/api/demand'
import { useRoute, useRouter } from 'vue-router'
import { getDict } from '@/api/common'
import { getMaterialList, getSysDictList } from '@/api/user'
import {
  allowOrRefuseQuo,
  getOrderByQuotationId,
  getOrderDetail,
  getOrderTimeLineAll,
  getOrderTimeLines
} from '@/api/order'
import { thousands } from '@/utils/thousands'
import { getUserId, getUserType } from '@/utils/auth'
import { thousand } from '@/utils/thousand'
import { ElMessageBox } from 'element-plus'
const showDeleteButton = ref(false)
const router = useRouter()
const route = useRoute()
const orderType = ref('')
const requirementId = route.query.requirementId
let quotationId = route.query.quotationId
let orderId = route.query.orderId
const demand = ref({})
const deliveryAddress = ref()
const materialId = ref()
const currency = ref('')
const orderType2 = ref(0)
const orderStatus = ref('')
// 切换图片
const imgList = ref([])
const currentImgSrc = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodavt.png')
const info = ref({ requirementId: requirementId, quotationId: quotationId })
const getDisplayedValue = (key) => {
  const value = demand.value[key.key]
  switch (key.key) {
  case 'needSample':
    return value === 1 ? 'YES' : 'NO'
  // case 'material':
  //   const matchedMaterial = materials.value.find(material => material.id === materialId.value)
  //   return matchedMaterial?.catalog2
  case 'DeliveryAddress':
    return deliveryAddress.value
  default:
    return value
  }
}

const userType = ref('0')
const initUserType = () => {
  userType.value = getUserType().toString()
}
initUserType()

const keys = ref([
  // { name: 'Product Name', key: 'productName' },
  { name: 'Quantity', key: 'quantity' },
  { name: 'Product Size (mm)', key: 'specification' },
  { name: 'Material', key: 'materialStr' },
  { name: 'Colour', key: 'pantoneCode' },
  { name: 'Process Requirement', key: 'processRequirement' },
  { name: 'Other Requirement', key: 'otherSpecialRequirent' },
  { name: 'Remark', key: 'remark' }
  // { name: 'Overall Budget', key: 'overallBudget' },
  // { name: 'Lead Time Requirement', key: 'expectedDeliveryDate' },
  // { name: 'Delivery address', key: 'deliveryAddressId' }
  // { name: 'Sample requirment', key: 'needSample' }
])

// 通用数据
const commonData = reactive([
  // { name: 'Overall Budget', key: 'overallBudgetStr' },
  { name: 'Lead Time Requirement', key: 'expectedDeliveryDate' },
  { name: 'Delivery address', key: 'deliveryAddressId' }
])
const initData = () => {
  if (getUserType() === '2') {
    commonData.push({ name: 'Overall Budget', key: 'overallBudget' })
  }
}
initData()

// 获取通用数据标题
const getCommonTitle = (key) => {
  return commonData.find(item => item.key === key.key).name
}

const getTitle = (key) => {
  return keys.value.find(item => item.key === key.key).name
}

// 产品2和后面的产品的value
const getDisplayedValue2 = (index, key) => {
  const value = productInfoList[index][key.key]
  switch (key.key) {
  case 'needSample':
    return value === 1 ? 'YES' : 'NO'
  // case 'material':
  //   const matchedMaterial = materials.value.find(material => material.id === materialId.value)
  //   return matchedMaterial?.catalog2
  case 'DeliveryAddress':
    return deliveryAddress.value
  default:
    return value
  }
}

// 材质列表
const materials = ref([])
// 获取材质列表
const getMaterialsList = async () => {
  const res = await getMaterialList()
  if (res.code === 200) {
    materials.value = res.data
  }
}
getMaterialsList()

const quotation = ref({})

const qutationList = ref([])
const getQuotationsByReId = async () => {
  if (requirementId != null) {
    const result = await getQuotationsByRequirementId(requirementId)
    console.log('qutationList result===' + result)
    if (result?.code === 200) {
      qutationList.value = result?.data
      for (const item of qutationList.value) {
        item.checked = false
      }
      if (qutationList.value.length > 0) {
        quotation.value = qutationList.value[0]
        quotation.value.checked = true
        quotationId = qutationList.value[0].quotationId
        orderId = qutationList.value[0].orderId
        if (quotation.value.currency) {
          if (quotation.value.currency === 'EUR') {
            currency.value = '€'
          } else if (quotation.value.currency === 'USD') {
            currency.value = '$'
          } else if (quotation.value.currency === 'CNY') {
            currency.value = '¥'
          }
        }
        if (qutationList.value[0].status === 2) {
          orderStatus.value = 'Quotation Received'
        } else if (qutationList.value[0].status === 3) {
          await getOrderTimeLine(orderId)
        }
      } else {
        orderStatus.value = 'Waiting for Quotation'
      }
    }
  }
}
// 订单
const order = ref({})

const getOrderTimeLine = async (orderId) => {
  const orderResult = await getOrderDetail(orderId)
  if (orderResult.code === 200) {
    order.value = orderResult.data
    console.log(order.value)
  }
  const result = await getOrderTimeLines(orderId)
  console.log('timeline result==', result)
  if (result.code === 200) {
    const timelineList = result.data
    if (timelineList.length > 0) {
      console.log('orderStatus==', order.value.orderStatus)
      console.log('orderType==', order.value.orderType)
      const time = timelineList[timelineList.length - 1]
      if (order.value.orderType === 1) {
        if (time.status === 0) {
          orderStatus.value = 'Order Created'
        } else if (time.status === 2) {
          orderStatus.value = 'Raw Matrial Processing'
        } else if (time.status === 101) {
          orderStatus.value = 'Sample Production'
        } else if (time.status === 102) {
          orderStatus.value = 'Sample Shipping'
        }
      } else if (order.value.orderType === 2) {
        if (time.status === 0) {
          orderStatus.value = 'Order Created'
        } else if (time.status === 2) {
          orderStatus.value = 'Raw Matrial Processing'
        } else if (time.status === 3) {
          orderStatus.value = 'Production'
        } else if (time.status === 4) {
          orderStatus.value = 'Packaging'
        } else if (time.status === 5) {
          orderStatus.value = 'Inspection'
        } else if (time.status === 6) {
          orderStatus.value = 'Sending to Port of Departure'
        } else if (time.status === 7) {
          orderStatus.value = 'In Transit'
        } else if (time.status === 8) {
          orderStatus.value = 'Local Delivery'
        }
      } else if (order.value.orderType === 3) {
        if (time.status === 0) {
          orderStatus.value = 'Order Created'
        } else if (time.status === 2) {
          orderStatus.value = 'Raw Matrial Processing'
        } else if (time.status === 101) {
          orderStatus.value = 'Sample Production'
        } else if (time.status === 102) {
          orderStatus.value = 'Sample Shipping'
        } else if (time.status === 3) {
          orderStatus.value = 'Mass Production'
        } else if (time.status === 4) {
          orderStatus.value = 'Packaging'
        } else if (time.status === 5) {
          orderStatus.value = 'Inspection'
        } else if (time.status === 6) {
          orderStatus.value = 'Sending to Port of Departure'
        } else if (time.status === 7) {
          orderStatus.value = 'In Transit'
        } else if (time.status === 8) {
          orderStatus.value = 'Local Delivery'
        }
      }
    }
  }
}

getQuotationsByReId()

const changeQuotation = (quotationid) => {
  for (const item of qutationList.value) {
    if (item.quotationId === quotationid) {
      item.checked = true
      quotation.value = item
      quotationId = quotationid
      orderId = item.orderId
    } else {
      item.checked = false
    }
  }
}

// 查询报价单

const quotationDetail = async () => {
  if (quotationId != null) {
    const result = await getQuotationDetail(quotationId)
    if (result?.code === 200) {
      quotation.value = result?.data
      if (quotation.value.currency) {
        if (quotation.value.currency === 'EUR') {
          currency.value = '€'
        } else if (quotation.value.currency === 'USD') {
          currency.value = '$'
        } else if (quotation.value.currency === 'CNY') {
          currency.value = '¥'
        }
      }
    }
  }
}
// quotationDetail()
// 查询订单
const orderEntity = ref({})
// 实际交货时间
const actualDeliveryTime = ref()
const getOrder = async () => {
  if (quotationId != null) {
    if (orderId === 'undefined') {
      orderId = 0
    }
    const result = await getOrderByQuotationId(quotationId, orderId)
    if (result?.code === 200) {
      orderEntity.value = result?.data
    }
    if (orderEntity?.value.actualDeliveryTime) {
      actualDeliveryTime.value = orderEntity.value.actualDeliveryTime
    }
    if (orderEntity?.value.orderType) {
      orderType2.value = orderEntity?.value.orderType
    }
  }
}
getOrder()

// 产品2和后面的产品
let productInfoList = reactive([])

const demandDetail = async () => {
  console.log('demandDetail in')
  const result = await getDemandDetail(requirementId)
  if (result?.code === 200) {
    demand.value = result?.data
    materialId.value = demand.value.material
    console.log(demand.value, 'demand.value')
    productInfoList = demand.value.productInfoList
    console.log(productInfoList, 'productInfoList')

    for (var item of demand.value?.clientImageList) {
      // console.log(item.imageUrl)
      // 如果后缀为pdf,就使用默认图片
      if (item.imageUrl.indexOf('.pdf') !== -1) {
        item.imageUrl = '	https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png'
      }
      imgList.value.push(item.imageUrl)
    }
    if (imgList.value.length > 0) {
      imgList.value = Array.from(new Set(imgList.value))
      currentImgSrc.value = imgList.value[0]
    }
    if (quotationId == null && requirementId != null && orderId == null) {
      if (demand.value?.client != null) {
        if (demand.value?.client.userId != null) {
          if (demand.value?.client.userId.toString() === getUserId()) {
            if (!demand.value?.supplierQuotationList || demand.value?.supplierQuotationList.length === 0) {
              showDeleteButton.value = true
            }
          }
        }
      }
    }
    // deliveryAddress.value = demand.value.clientDeliveryAddress.shippingAddress
  }
}

const quatationDetailId = reactive({
  id: 2356,
  orderType: 'sampleOrder',
  promptContent: 'Do you want to decline this quotation?'
})
// 使用 provide 函数向接收弹窗和查看碳值提供数据
provide('quatationDetailId', quatationDetailId)

demandDetail()

const changeImg = (img) => {
  currentImgSrc.value = img
  // 如果后缀为pdf,就使用默认图片
  if (img.indexOf('.pdf') !== -1) {
    currentImgSrc.value = '	https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png'
  }
}

// 产品详情是否展开
const isShowRequirementDetail = ref(true)
const showRequirementDetail = () => {
  isShowRequirementDetail.value = !isShowRequirementDetail.value
  console.log(isShowRequirementDetail.value)
}

// 查看碳值的弹窗
const showCarbonValue = ref(false)
// 打开查看碳值的弹窗
const openCarbonValue = () => {
  showCarbonValue.value = true
}
// 关闭查看碳值的弹窗
const closeCarbonValue = (data) => {
  if (data === 'close') {
    showCarbonValue.value = false
  }
}

// 发送问题的弹窗
const showContact = ref(false)
// 打开签大货单弹窗
const openContact = () => {
  console.log('发送问题')
  showContact.value = true
}
// 关闭签大货单的弹窗
const closeContact = (data) => {
  showContact.value = false
}

// 签单，填写样品数量的弹窗
const showSampleQuantity = ref(false)
// 打开填写样品数量弹窗
const openSampleQuantity = () => {
  console.log('签订单,询问是否需要样品')
  showSampleQuantity.value = true
}
// 关闭填写样品数量的弹窗
const closeSampleQuantity = (data) => {
  if (data === 'close') {
    showSampleQuantity.value = false
  } else {
    orderType.value = data
    showSampleQuantity.value = false
    quotationDetail()
    getOrder()
  }
}

// 不接受报价弹窗
const showRejectQuotation = ref(false)
// 打开不接受报价弹窗
const openRejectQuotation = () => {
  showRejectQuotation.value = true
  // quatationDetailId.type = 'reject'
}
// 关闭不接受报价的弹窗
const closeRejectQuotation = async (data) => {
  if (data === 'close') {
    showRejectQuotation.value = false
  }
  console.log(data, 'data')
  if (data === 'confirm') {
    // 确认拒绝这个报价
    const res = await allowOrRefuseQuo({ quotationId: quotationId, quotationStatus: 4, orderType: 0 })
    if (res.code === 200) {
      showRejectQuotation.value = false
      quotationDetail()
      getOrder()
    }
  }
}

// 跳转海运信息详情
const carbonTracking = () => {
  console.log('跳转海运信息详情')
  router.push({
    path: 'shipping',
    query: {
      orderId: orderId,
      containerNumber: orderEntity.value.orderTransportationInformation?.sealNumber
    }
  })
}

const deleteRequirementMethod = async () => {
  ElMessageBox.confirm(
    'This request will be permanently deleted and will not be restored.',
    'Delete',
    {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warning'
    }
  ).then(async () => {
    const res = await deleteRequirement(requirementId)
    if (res.code === 200) {
      router.go(-1)
    }
  })
    .catch(() => {

    })
}

</script>

<style lang="scss" scoped>
.container {
  background-color: #ffffff;
  line-height: 1;
  text-align: left;
  display: flex;
  flex-direction: column;

  &_content {
    width: 1600px;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;

    // 左侧图片
    &_left {
      width: 32%;
      font-size: 0;

      img {
        width: 500px;
        height: 500px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 6px;
      }

      &_imgs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        img {
          width: 80px;
          height: 80px;
          overflow: hidden;
          object-fit: cover;
          border-radius: 4px;
          margin-right: 15px;
          cursor: pointer;
          opacity: 0.3;

          &:nth-child(5n) {
            margin-right: 0px;
          }
        }

        &_isSelected {
          opacity: 1 !important;
          box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.5);
        }
      }

      &_carbon-tracking {
        width: 500px;
        height: 60px;
        font-size: 16px;
        line-height: 60px;
        font-weight: 700;
        color: rgba(12, 150, 69, 1);
        margin-top: 40px;
        background-image: url("../../assets/images/customer/carbonTracking_bg.png");
        background-size: cover;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 30px;
          height: 16px;
          object-fit: cover;
          margin-left: 20px;
        }
      }

      // 按钮
      &_btn,
      &_btn1 {
        width: 500px;
        height: 60px;
        background-color: #fff;
        border: 1px solid rgba(12, 150, 69, 1);
        font-size: 16px;
        font-weight: 600;
        color: rgba(12, 150, 69, 1);
        border-radius: 6px;
        margin-top: 15px;
        line-height: 60px;
        text-align: center;

        &:hover {
          background-color: rgba(12, 150, 69, 1);
          color: #fff;
          cursor: pointer;
        }
      }

      &_btn2 {
        width: 500px;
        height: 60px;
        background-color: #fff;
        border: 1px solid red;
        font-size: 16px;
        font-weight: 600;
        color: red;
        border-radius: 6px;
        margin-top: 15px;
        line-height: 60px;
        text-align: center;

        &:hover {
          background-color: red;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    &_right {
      width: 66%;
      &_requirement {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        // margin-left: 410px;
        margin-bottom: 50px;

        // 标题
        &_titleBox {
          width: 100%;
          border: 1px solid rgba(255, 255, 255, 1);
          box-sizing: border-box;
          border-bottom: none;
          background: linear-gradient(
            180deg,
            rgba(39, 174, 96, 0.1) 0%,
            rgba(39, 174, 96, 0) 100%
          );
          display: flex;
          justify-content: space-between;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            margin: 40px 0 0 50px;
          }
          &_order {
            font-size: 24px;
            color: red;
            font-weight: 700;
            margin: 40px 40px 0 50px;
          }

          &_quantity {
            font-size: 16px;
            color: rgba(24, 24, 24, 1);
            font-weight: 600;
            margin: 40px 50px 20px 0;

            span {
              color: rgba(173, 173, 173, 1);
              font-weight: 300;
              margin-right: 10px;
            }
          }
        }

        // 产品详情
        &_details {
          // margin-top: -10px;
          background-image: url(../../assets/images/bg.svg);
          background-repeat: no-repeat;
          background-size: 500px 500px;
          background-position: center right;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            // border-top: 1px dashed rgba(39, 174, 96, .6);
            padding-top: 20px;
            margin: 20px 50px 0px 50px;
          }

          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &_title {
              font-size: 14px;
              color: rgba(150, 150, 150, 1);
              font-weight: 300;
              line-height: 16px;
              width: 200px;
            }

            &_value {
              font-size: 16px;
              color: rgba(24, 24, 24, 1);
            }

            &:last-child {
              // border: none;
            }
          }

          .file {
            display: flex;
            justify-content: space-between;

            &_left {
              display: flex;
              width: 500px;
            }

            &_download {
              font-size: 16px;
              color: rgba(39, 174, 96, 1);
              cursor: pointer;
            }
          }
        }

        // 通用数据
        .commonData {
          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &:first-child {
              border-top: 1px dashed rgba(39, 174, 96, 0.6) !important;
              margin-top: 50px !important;
            }
          }
        }

        // 折叠
        &_expand {
          padding: 0 50px 30px;

          &_box {
            font-size: 0;
            color: rgba(36, 89, 2, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 30px;
            border-top: 1px solid rgba(240, 240, 240, 1);
            cursor: pointer;

            span {
              font-size: 14px;
            }

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      // 需求详情
      .requirement {
        &_titleBox {
          background: #ffffff;
        }
      }

      // 报价详情
      .quotation {
        margin-bottom: 200px;

        .container_content_right_requirement_titleBox {
          display: flex;
          align-items: center;

          &_title {
            line-height: 50px;
            margin: 30px 0 30px 50px;
          }

          &_left {
            height: 50px;
            margin-right: 50px;
            border-radius: 6px;
            background-color: rgba(39, 174, 96, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;
            cursor: pointer;

            img {
              width: 24px;
              height: 24px;
              margin-right: 15px;

              &:last-child {
                width: 18px;
                height: 18px;
                margin-right: 15px;
              }
            }

            span {
              font-size: 28px;
              line-height: 30px;
              font-weight: 800;
              color: #ffffff;

              & + .co {
                font-size: 16px;
                color: rgba(255, 255, 255, 0.8);
                font-weight: 300;
                margin-left: 10px;
                margin-right: 20px;
              }
            }
          }
        }

        // 底部按钮
        .container_content_right_requirement_details_list_btn {
          display: flex;
          justify-content: center;
          padding: 60px 0;

          &_reject,
          &_accept {
            font-size: 14px;
            font-weight: 700;
            margin: 0 15px;
            padding: 10px 30px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }

          &_reject {
            color: rgba(255, 151, 0, 1);
            border: 1px solid rgba(255, 151, 0, 1);
            background-color: #fff;

            img {
              width: 25px;
              height: 25px;
              margin-right: 5px;
            }

            &:hover {
              // color: #fff;
              background-color: rgba(255, 151, 0, 0.1);
            }
          }

          &_accept {
            color: #fff;
            background-color: rgba(39, 174, 96, 1);

            &:hover {
              background-color: rgba(12, 150, 69, 1);
            }
          }
        }

        // 报价单状态
        .container_content_right_requirement_details_list_status {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 60px 0;

          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }

          span {
            font-size: 14px;
            font-weight: 700;
            color: rgba(39, 174, 96, 1);
            padding: 15px 0px;
          }

          .reject {
            color: rgba(255, 151, 0, 1);
          }
        }
      }

      &_btn {
        display: flex;
        margin-bottom: 60px;
        justify-content: center;

        .back_btn {
          font-size: 14px;
          font-weight: 700;
          padding: 20px 30px;
          margin: 0 25px;
          border-radius: 4px;
          cursor: pointer;
        }

        &_back {
          color: rgba(255, 255, 255, 1);
          background-color: rgba(39, 174, 96, 1);
        }
      }
    }
  }
  &_main {
    width: 1600px;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    &_top {
      border: 1px rgb(12, 150, 69) solid;
      border-radius: 10px;
      padding: 25px 0 20px 0px;
      display: flex;
      flex-direction: row;
      &_left {
        width: 22%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &_info {
          height: 180px;
        }
        &_title {
          padding-left: 40px;
          font-size: 28px;
          justify-content: center;
          margin-top: 50px;
          color: #678192;
          font-weight: bold;
        }
        &_desc {
          padding-left: 60px;
          font-size: 16px;
          margin-top: 30px;
          justify-content: center;
          color: #678192;
          font-weight: bold;
        }
        &_table {
          width: 100%;
          margin-top: 20px;
          &_titleline {
            width: 100%;
            background-color: #f7f7f7;
            padding: 20px 40px;
            color: #678192;
            font-size: 22px;
            font-weight: 700;
          }

          &_line {
            font-size: 18px;
            padding: 0px 0px 0px 40px;
          }
        }
      }
      &_right {
        width: 78%;
        display: flex;
        flex-direction: row;

        &_info {
          height: 200px;
        }
        &_table {
          width: 100%;
          &_item {
            font-size: 18px;
            text-align: center;
          }
        }
        &_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          &_title {
            font-size: 28px;
            color: #e78b82;
            font-weight: bold;
            justify-content: center;
            align-self: center;
            text-align: center;
          }
          &_img {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            img {
              width: 80px;
            }
          }
          &_status {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 15px;
            &_btn {
              display: flex;

              img {
                width: 14px;
                height: 14px;
                margin-right: 10px;
              }

              span {
                font-size: 14px;
                font-weight: 700;
                color: rgba(39, 174, 96, 1);
                padding: 15px 0px;
              }
              &_reject {
                font-size: 14px;
                font-weight: 700;
                margin: 0 5px;
                padding: 10px 30px;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                align-items: center;
                color: rgba(255, 151, 0, 1);
                border: 1px solid rgba(255, 151, 0, 1);
                background-color: #fff;

                img {
                  width: 25px;
                  height: 25px;
                  margin-right: 5px;
                }

                &:hover {
                  // color: #fff;
                  background-color: rgba(255, 151, 0, 0.1);
                }
              }
              &_accept {
                font-size: 14px;
                font-weight: 700;
                margin: 0 5px;
                padding: 10px 20px;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                align-items: center;
                color: #fff;
                background-color: rgba(39, 174, 96, 1);

                &:hover {
                  background-color: rgba(12, 150, 69, 1);
                }
              }
            }
          }
          &_heandline {
            margin-top: 1px;
            height: 62px;
            width: 100%;
            background-color: #f7f7f7;
          }
        }
      }
    }
    &_title {
      border: 1px rgb(12, 150, 69) solid;
      border-radius: 10px;
      padding: 20px 20px 20px 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      &_info {
        background-color: rgb(255, 151, 0);
        color: white;
        padding: 20px 30px;
        border-radius: 10px;
        border: 1px rgb(255, 151, 0) solid;
      }
      &_infounselect {
        color: rgb(255, 151, 0);
        padding: 20px 30px;
        border-radius: 10px;
        border: 1px rgb(255, 151, 0) solid;
      }
    }
    &_right {
      width: 100%;
      &_requirement {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        // margin-left: 410px;
        margin-bottom: 50px;

        // 标题
        &_titleBox {
          width: 100%;
          border: 1px solid rgba(255, 255, 255, 1);
          box-sizing: border-box;
          border-bottom: none;
          background: linear-gradient(
            180deg,
            rgba(39, 174, 96, 0.1) 0%,
            rgba(39, 174, 96, 0) 100%
          );
          display: flex;
          justify-content: space-between;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            margin: 40px 0 0 50px;
          }

          &_quantity {
            font-size: 16px;
            color: rgba(24, 24, 24, 1);
            font-weight: 600;
            margin: 40px 50px 20px 0;

            span {
              color: rgba(173, 173, 173, 1);
              font-weight: 300;
              margin-right: 10px;
            }
          }
        }

        // 产品详情
        &_details {
          // margin-top: -10px;
          background-image: url(../../assets/images/bg.svg);
          background-repeat: no-repeat;
          background-size: 500px 500px;
          background-position: center right;

          &_title {
            font-size: 24px;
            color: rgba(24, 24, 24, 1);
            font-weight: 700;
            // border-top: 1px dashed rgba(39, 174, 96, .6);
            padding-top: 20px;
            margin: 20px 50px 0px 50px;
          }

          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &_title {
              font-size: 14px;
              color: rgba(150, 150, 150, 1);
              font-weight: 300;
              line-height: 16px;
              width: 200px;
            }

            &_value {
              font-size: 16px;
              color: rgba(24, 24, 24, 1);
            }

            &:last-child {
              // border: none;
            }
          }

          .file {
            display: flex;
            justify-content: space-between;

            &_left {
              display: flex;
              width: 500px;
            }

            &_download {
              font-size: 16px;
              color: rgba(39, 174, 96, 1);
              cursor: pointer;
            }
          }
        }

        // 通用数据
        .commonData {
          &_list {
            display: flex;
            margin: 0 50px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding: 20px 0;

            &:first-child {
              border-top: 1px dashed rgba(39, 174, 96, 0.6) !important;
              margin-top: 50px !important;
            }
          }
        }

        // 折叠
        &_expand {
          padding: 0 50px 30px;

          &_box {
            font-size: 0;
            color: rgba(36, 89, 2, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 30px;
            border-top: 1px solid rgba(240, 240, 240, 1);
            cursor: pointer;

            span {
              font-size: 14px;
            }

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      // 需求详情
      .requirement {
        &_titleBox {
          background: #ffffff;
        }
      }

      // 报价详情
      .quotation {
        margin-bottom: 200px;

        .container_main_right_requirement_titleBox {
          display: flex;
          align-items: center;

          &_title {
            line-height: 50px;
            margin: 30px 0 30px 50px;
          }

          &_left {
            height: 50px;
            margin-right: 50px;
            border-radius: 6px;
            background-color: rgba(39, 174, 96, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;
            cursor: pointer;

            img {
              width: 24px;
              height: 24px;
              margin-right: 15px;

              &:last-child {
                width: 18px;
                height: 18px;
                margin-right: 15px;
              }
            }

            span {
              font-size: 28px;
              line-height: 30px;
              font-weight: 800;
              color: #ffffff;

              & + .co {
                font-size: 16px;
                color: rgba(255, 255, 255, 0.8);
                font-weight: 300;
                margin-left: 10px;
                margin-right: 20px;
              }
            }
          }
        }

        // 底部按钮
        .container_content_right_requirement_details_list_btn {
          display: flex;
          justify-content: center;
          padding: 60px 0;

          &_reject,
          &_accept {
            font-size: 14px;
            font-weight: 700;
            margin: 0 15px;
            padding: 10px 30px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }

          &_reject {
            color: rgba(255, 151, 0, 1);
            border: 1px solid rgba(255, 151, 0, 1);
            background-color: #fff;

            img {
              width: 25px;
              height: 25px;
              margin-right: 5px;
            }

            &:hover {
              // color: #fff;
              background-color: rgba(255, 151, 0, 0.1);
            }
          }

          &_accept {
            color: #fff;
            background-color: rgba(39, 174, 96, 1);

            &:hover {
              background-color: rgba(12, 150, 69, 1);
            }
          }
        }

        // 底部按钮
        .container_main_right_requirement_details_list_btn {
          display: flex;
          justify-content: center;
          padding: 60px 0;

          &_reject,
          &_accept {
            font-size: 14px;
            font-weight: 700;
            margin: 0 15px;
            padding: 10px 30px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
          }

          &_reject {
            color: rgba(255, 151, 0, 1);
            border: 1px solid rgba(255, 151, 0, 1);
            background-color: #fff;

            img {
              width: 25px;
              height: 25px;
              margin-right: 5px;
            }

            &:hover {
              // color: #fff;
              background-color: rgba(255, 151, 0, 0.1);
            }
          }

          &_accept {
            color: #fff;
            background-color: rgba(39, 174, 96, 1);

            &:hover {
              background-color: rgba(12, 150, 69, 1);
            }
          }
        }

        // 报价单状态
        .container_main_right_requirement_details_list_status {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 60px 0;

          img {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }

          span {
            font-size: 14px;
            font-weight: 700;
            color: rgba(39, 174, 96, 1);
            padding: 15px 0px;
          }

          .reject {
            color: rgba(255, 151, 0, 1);
          }
        }
      }

      &_btn {
        display: flex;
        margin-bottom: 60px;
        justify-content: center;

        .back_btn {
          font-size: 14px;
          font-weight: 700;
          padding: 20px 30px;
          margin: 0 25px;
          border-radius: 4px;
          cursor: pointer;
        }

        &_back {
          color: rgba(255, 255, 255, 1);
          background-color: rgba(39, 174, 96, 1);
        }
      }
    }
  }
}

.btn-back {
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: rgb(39, 174, 96);
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-back:hover {
  background-color: rgb(39, 174, 96);
}
</style>

import { render, staticRenderFns } from "./SupplierHome.vue?vue&type=template&id=526bebe9&scoped=true"
import script from "./SupplierHome.vue?vue&type=script&setup=true&name=SupplierHome&lang=js"
export * from "./SupplierHome.vue?vue&type=script&setup=true&name=SupplierHome&lang=js"
import style0 from "./SupplierHome.vue?vue&type=style&index=0&id=526bebe9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526bebe9",
  null
  
)

export default component.exports
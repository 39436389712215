<!-- 签正式订单 -->
<template>
  <div class="container">
    <div class="container_form">
      <div class="container_form_top">Send customize request</div>

      <div class="container_form_content">
        <div class="container_form_content_product" v-for="(item, index) in productForms" :key="index">
          <div class="container_form_content_product_title">
            <div class="container_form_content_product_title_text">Product {{ index + 1 }}</div>
            <span v-if="index != 0" @click="deleteProduct(index)">delete</span>
          </div>
          <el-form ref="productRef" :model="item" :rules="productRules">
            <!-- Product Name -->
            <el-form-item label="Product Name" prop="productName">
              <el-input v-model="item.productName" placeholder="Enter Product Name"></el-input>
            </el-form-item>

            <!-- Quantity -->
            <el-form-item label="Quantity" prop="quantity">
              <el-input v-model="item.quantity" placeholder="Enter Quantity" type="number" onkeyup="this.value= this.value.match(/^\+?[1-9]\d*$/) ? this.value.match(/^\+?[1-9]\d*$/)[0] : ''"></el-input>
            </el-form-item>

            <!-- Product size -->
            <el-form-item label="Product Size" prop="specification">
              <el-input v-model="item.specification" placeholder="XXXmm x XXXmm x XXXmm (L×W×H)"></el-input>
            </el-form-item>

            <!-- Material 选择 -->
            <el-form-item label="Material" prop="materialStr">
              <el-input v-model="item.materialStr" placeholder="Enter Material"></el-input>
<!--              <el-select v-model="item.material" placeholder="Select Material">
                <el-option v-for="(item, index) in materials" :key="index" :label="item.content" :value="item.id"></el-option>
              </el-select>-->
            </el-form-item>

            <!-- Color -->
            <el-form-item label="Colour System" prop="selectColor">
              <el-select v-model="item.selectColor" placeholder="Select" style="width: 165px">
                <el-option v-for="(item, index) in selectColor" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Colour" prop="color">
              <el-input id="color-input" v-model="item.color" placeholder="Enter Colour"></el-input>
            </el-form-item>

            <!-- Proess Requirments 选择 -->
            <el-form-item label="Process Requirement" prop="processRequirement">
              <el-select v-model="item.processRequirement" placeholder="Select Process Requirement">
                <el-option v-for="(item, index) in proessRequirments" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>

            <!-- Other specific requirments -->
            <el-form-item label="Other Requirement" prop="otherSpecialRequirent" :rules="[{ required: item.processRequirement == 'other processes required' ? true : false, message: 'Required' }]">
              <el-input v-model="item.otherSpecialRequirent" placeholder="Enter Other Requirement"></el-input>
            </el-form-item>

            <!-- Upload qualification certificate -->
            <el-form-item label="Upload Original Artwork" prop="uploadQualificationCertificate">
              <el-upload multiple drag :action="uploadFileUrl" :headers="headers" :before-upload="beforeUpload" :on-remove="handleRemove.bind(null, { 'index': index })" :on-success="handleAvatarSuccess.bind(null, { 'index': index })">
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              </el-upload>
            </el-form-item>

            <!-- Original Sample picture -->
            <el-form-item label="Upload Original Sample Picture" prop="uploadOriginalSamplepicture">
              <el-upload multiple drag :action="uploadFileUrl" :headers="headers" :before-upload="beforeUpload" :on-remove="handleNewRemove.bind(null, { 'index': index })" :on-success="handleNewAvatarSuccess.bind(null, { 'index': index })">
                <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
              </el-upload>
            </el-form-item>

            <!-- remark -->
            <el-form-item label="Remarks" prop="remark">
              <el-input v-model="item.remark" placeholder="Enter Remarks"></el-input>
            </el-form-item>

            <div class="addProduct">
              <el-button class="addProduct_btn" @click="addProduct">Add Product</el-button>
            </div>
            <div class="el-item-line"></div>
          </el-form>
        </div>


        <el-form :model="forms" @submit.native.prevent="submitForm" :rules="rules" ref="formRef">

          <!-- Currency 选择 -->
          <el-form-item label="Currency" prop="currency">
            <el-select v-model="forms.currency" placeholder="Select Currency">
              <el-option label="EUR" value="EUR"></el-option>
              <el-option label="USD" value="USD"></el-option>
              <el-option label="CNY" value="CNY"></el-option>
            </el-select>
          </el-form-item>

          <!-- Overall Budget(If known) -->
          <el-form-item label="Overall Budget (If known)" prop="overallBudget">
            <el-input v-model="forms.overallBudget" placeholder="Enter Overall Budget(If known)" type="number"></el-input>
          </el-form-item>

          <!-- Leadtime Requirment -->
          <el-form-item label="Lead Time Requirement" prop="expectedDeliveryDate">
            <el-config-provider :locale="enLocale">
              <el-date-picker v-model="forms.expectedDeliveryDate" type="date" placeholder="Lead Time Requirement" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></el-date-picker>
            </el-config-provider>

          </el-form-item>

          <!-- Price term -->
          <el-form-item label="Price Term" prop="priceTerm">
            <el-select v-model="forms.priceTerm" placeholder="Select Price Term">
              <el-option v-for="(item, index) in priceTerm" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>

            <el-popover placement="top-end" title="Price Term" :width="500" trigger="hover" :content="priceTermTip">
              <template #reference>
                <el-button class="labelBtn" type="text"><el-icon>
                    <InfoFilled />
                  </el-icon></el-button>
              </template>
            </el-popover>

          </el-form-item>

          <!-- Port of departure -->
          <el-form-item label="Delivery Port" prop="port">
            <el-select v-model="forms.port" placeholder="Select Delivery Port" @change="portChange">
              <el-option v-for="item in Object.keys(portLocations)" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <!-- Delivery address -->
          <el-form-item label="Delivery Address" prop="deliveryAddress">
            <el-input v-model="forms.deliveryAddress" placeholder="Select Delivery Address" @click="openMap"></el-input>
          </el-form-item>

          <!-- Distance(km) -->
          <el-form-item label="Distance (km)" prop="distance">
            <el-input disabled v-model="forms.distance"></el-input>
          </el-form-item>

          <!--                    <el-form-item label="Transport mode" prop="transportationTypeId">
                        <el-select v-model="forms.transportationTypeId" placeholder="Select Mode of Transport">
                            <el-option v-for="(item, index) in transTypes" :key="index" :label="item.type" :value="item.id"></el-option>
                        </el-select>

                    </el-form-item>-->
          <!-- <div class="address">add new address</div> -->

          <div class="el-item-line"></div>

          <el-form-item class="el-btn">
            <el-button type="primary" @click="submitForm(formRef)">Send to suppliers</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- bing地图 -->
    <BMap v-on:customEvent="mapCustomEvent" v-if="showMap" :startlocation="startlocation" />
    <!-- foot   -->
    <Foot />
  </div>

  <!-- 提交弹窗 -->
  <el-dialog v-model="showSubmit" width="40%" :before-close="handleCloseSubmit">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <div class="my-header_title">Important</div>
      </div>
    </template>
    <div class="dialogContent">
      <div class="dialogContent_text">
        Please noted that any incompleted or insuffient information might caused delay and unaccurate quotation. Any
        changes or additional information will incur changes in quotation. Please confirm all information submitting
        are
        ture and faithful.
      </div>
      <div class="dialogContent_submit" @click="toSubmit">
        Send to suppliers
      </div>
    </div>

  </el-dialog>
</template>

<script setup >
import { reactive, ref, toRefs } from '@vue/reactivity'
import { getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import Foot from '../../components/Foot.vue'
import BMap from '../customer/dialog/BMap.vue'
import { getProductDetail } from '@/api/product'
import { addDemand } from '@/api/demand'
import { target } from '@/utils/URL'
import { getMaterialList, getSysDictList } from '@/api/user'
import { getTransportType } from '@/api/transport'
import { getToken } from '@/utils/auth'
import enLocale from 'element-plus/lib/locale/lang/en'; // 引入英文语言包

const route = useRoute()
const router = useRouter()

// 获取路由参数
// const quatationDetailId = route.query;
const productId = route.query.productId
const index = route.query.index
const query = reactive({ queryParams: { productId: '' } })
const { queryParams } = toRefs(query)
const product = ref({})
const productData = ref([{}])
const showSubmit = ref(false)
const startlocation = ref([53.34749, -6.25276])
const consave = ref(true)
// 产品表单数据，数组对象
const productForms = reactive([{
  productName: '',
  quantity: '',
  specification: '',
  materialStr: '',
  color: '',
  selectColor: '',
  processRequirement: '',
  otherSpecialRequirent: '',
  artworkList: [],
  pictureNewList: [],
  remark: '',
}])

const productDetail = async () => {
  console.log('productId:' + productId)
  if (productId != null) {
    queryParams.value.productId = productId
    const result = await getProductDetail(queryParams.value)
    if (result?.code === 200) {
      product.value = result?.data
      productData.value = result.data.supplierProductSkuList
      if (queryParams.value.productId && Object.keys(product.value).length !== 0) {
        if (result.data.supplierProductSkuList != null && result.data.supplierProductSkuList.length > 0) {
          productForms.splice(0, productForms.length)
          for (var item of result.data.supplierProductSkuList) {
            var data = {};
            if (product.value.productName) {
              data.productName = product.value.productName
            }
            if (item.materialId) {
              data.material = item.materialId
            }
            if (item.specification) {
              data.specification = item.specification
            }
            productForms.push(data)
          }
          console.log('productForms', productForms)
        }
      }
    }
  }
}
// 价格解释
const priceTermTip = ref(`EXW (Ex Works): Seller makes goods available, and buyer arranges transportaDon
and assumes all costs and risks
FOB(Free On Board): Seller delivers goods to a designated port or loading point, and
buyer assumes costs and risks once goods are loaded onto the ship.
  CIF(Cost, Insurance and Freight): Seller delivers goods to a designated desDnaDon
port, covers transportaDon and insurance costs, and transfers risks to the buyer upon
arrival.
    DDP(Delivered Duty Paid): Seller delivers goods to a designated desDnaDon, handles
customs clearance, pays all costs and duDes, and transfers risks to the buyer.
TO DOOR: Seller handles all aspects of the shipment to ensure a smooth delivery to
the buyer's doorstep.`)


// 添加产品表单
const addProduct = () => {
  productForms.push({
    productName: '',
    quantity: '',
    specification: '',
    materialStr: '',
    color: '',
    selectColor: '',
    processRequirement: '',
    otherSpecialRequirent: '',
    artworkList: [],
    pictureNewList: [],
    remark: '',
  })
}

// 删除产品表单
const deleteProduct = (index) => {
  productForms.splice(index, 1)
}

// 产品表单验证
const productRules = reactive({
  productName: [{ required: true, message: 'Required' }],
  quantity: [{ required: true, message: 'Required' }],
  specification: [{ required: true, message: 'Required' }],
  materialStr: [{ required: true, message: 'Required' }],
  processRequirement: [{ required: true, message: 'Required' }]
  // otherSpecialRequirent: [{ required: false, message: 'Required' }],
})

// 选择文件
const originalArtworkOnChange = (obj, file, fileList) => {
  console.log('file', file)
  console.log('fileList', fileList)
  console.log('obj', obj.index)
  // fileList.forEach((item) => {
  //   if (item.status === 'success') {
  //     forms.value.artworkList.push(item.response.url)
  //   }
  // })
}

// 表单数据
const forms = ref({
  overallBudget: '',
  expectedDeliveryDate: '',
  currency: '',
  priceTerm: '',
  deliveryAddress: '',
  distance: '',
  // picUrls: [],
  port: 'Port Of Dublin',
  // transportationTypeId: '',
})

// 验证表单不为空
const validate = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Required'))
  } else {
    callback()
  }
}


// 需要验证的表单
const rules = reactive({
  priceTerm: [{ validator: validate, required: true, message: 'Required' }],
  port: [{ validator: validate, required: true, message: 'Required' }],
  deliveryAddress: [{ validator: validate, required: true, message: 'Required' }],
  distance: [{ validator: validate, required: true, message: 'Required' }],
  expectedDeliveryDate: [{ validator: validate, required: true, message: 'Required' }],
  otherSpecialRequirent: [{ validator: validate, required: true, message: 'Required' }],
})

// const material = ref(['ABS', 'PP', 'PVC', 'PC', 'PA', 'PMMA', 'POM', 'PE'])
const materials = ref([])
// 获取材质列表
const getMaterialsList = async () => {
  const res = await getMaterialList()
  if (res.code === 200) {
    materials.value = res.data
  }
}
const transTypes = ref([])
const getTransTypes = async () => {
  const result = await getTransportType()
  console.log('results===', result)
  if (result.code === 200) {
    transTypes.value = result.data
  }
}
getTransTypes()

getMaterialsList()
const selectColor = ref(['Pantone', 'RAL', 'CMYK'])
const proessRequirments = ref(['Oli', 'Laminating', 'UV', 'Bronzing', 'Concave-convex', 'other processes required'])
// const priceTerm = ref(['EXW', 'FOB', 'CIF', 'DDP', 'TO DOOR'])
const priceTerm = ref([])
// 获取商品类别
const getDictType = async () => {
  const res = await getSysDictList({ type: 'eko_price_term' })
  if (res.code === 200) {
    priceTerm.value = res.data
  }
}
getDictType()
const sampleRequirment = ref(['Yes', 'No'])
const formRef = ref()
const productRef = ref(null)

const instance = getCurrentInstance()

const validateForm1 = () => {
  const mainForm = instance.refs.formRef
  return new Promise((resolve, reject) => {
    mainForm.validate((valid) => {
      if (valid) {
        // 表单1验证通过
        resolve()
      } else {
        // 表单1验证未通过
        reject('Form 1 validation failed')
      }
    })
  })
}

const validateProductRef = () => {
  return new Promise((resolve, reject) => {
    const productRef = instance.refs.productRef
    // 循环
    const promises = productRef.map((item) => {
      return new Promise((resolve, reject) => {
        item.validate((valid) => {
          if (valid) {
            // 表单验证通过
            resolve()
          } else {
            // 表单验证未通过
            reject('Form 2 validation failed')
          }
        })
      })
    })
    Promise.all(promises)
      .then(() => {
        resolve() // 所有表单验证通过

      })
      .catch((error) => {
        reject(error) // 有表单验证未通过
      })
  })
}

const submitForm = async (formEl) => {
  Promise.all([validateProductRef(), validateForm1()]).then(() => {
    showSubmit.value = true
  }).catch((error) => {
  })

}

const toSubmit = async () => {
  if (!consave.value) {
    return
  }


  console.log(forms.value)
  if (forms.value.color && forms.value.selectColor) {
    forms.value.pantoneCode = forms.value.selectColor + forms.value.color
  }
  if (forms.value.deliveryAddress) {
    forms.value.deliveryAddressId = forms.value.deliveryAddress
  }
  // forms.value.certificateList = picUrls.value
  // forms.value.pictureNewList = picNewUrls.value

  // 循环productForms,添加pantoneCode，值为color+selectColor
  for (const item of productForms) {
    if (item.color && item.selectColor) {
      item.pantoneCode = item.selectColor + item.color
      delete item.color
      delete item.selectColor
    }
  }

  let data = {
    productInfoList: productForms,
    ...forms.value
  }

  console.log('data', data)
  // return

  consave.value = false
  const result = await addDemand(data)
  if (result.code === 200) {
    showSubmit.value = false
    consave.value = true
    ElMessage.success('Saved successfully')
    router.push({
      path: '/'
    })
  }
}

const handleCloseSubmit = (done) => {
  showSubmit.value = false
  done()
}

// 上传文件
const uploadFileUrl = ref(target + 'common/upload') // 上传的图片服务器地址
const headers = ref({
  Authorization: getToken()
}) // 设置上传的请求头部

// 文件上传之前
const beforeUpload = (file) => {
  // const isLt2M = file.size / 1024 / 1024 < 50
  // if (!isLt2M) {
  //   ElMessage.error('The size of the uploaded file cannot exceed 50MB!')
  // }
  // return isLt2M
  return true
}

// 上传证书相关
const picUrls = ref([])

// 文件列表移除文件
const handleRemove = (obj, uploadFile, uploadFiles) => {
  productForms[obj.index].artworkList = []
  for (const e of uploadFiles) {
    productForms[obj.index].artworkList.push({ 'imageUrl': e.response.url })
  }
}

// 上传成功
const handleAvatarSuccess = (obj, response, uploadFile, uploadFiles) => {
  productForms[obj.index].artworkList = []
  for (const e of uploadFiles) {
    productForms[obj.index].artworkList.push({ 'imageUrl': e.response.url })
  }
  // console.log(productForms, 'productForms')
}

// 上传样品图片相关
const fileNewList = ref([])
const picNewUrls = ref([])

// 文件列表移除文件
const handleNewRemove = (obj, uploadFile, uploadFiles) => {
  productForms[obj.index].pictureNewList = []
  for (const e of uploadFiles) {
    productForms[obj.index].pictureNewList.push(e.response.url)
  }
}

// 上传成功
const handleNewAvatarSuccess = (obj, response, uploadFile, uploadFiles) => {
  productForms[obj.index].pictureNewList = []
  for (const e of uploadFiles) {
    productForms[obj.index].pictureNewList.push(e.response.url)
  }
  // console.log(productForms, 'productForms')
}




const portLocations = ref({
  'Port Of Dublin': [53.34749, -6.25276]
})
const portChange = (val) => {
  console.log('选择了港口', val, portLocations.value[val])
  startlocation.value = portLocations.value[val]
  forms.value.distance = ''
  forms.value.deliveryAddress = ''
}

// 地图弹窗
// 接收是否关闭地图弹窗
const showMap = ref(false)
const openMap = (val) => {
  showMap.value = true
}
// 接收弹窗的地图数据：距离，地址
const mapCustomEvent = (data) => {
  showMap.value = false
  console.log('地图返回的数据:', data.distance, data.address)

  // 返回的数据不为空时，赋值
  if (data.distance) {
    forms.value.distance = data.distance
  }
  if (data.address) {
    forms.value.deliveryAddress = data.address
  }
}

productDetail()
</script>

<style lang="scss" scoped>
@use "../../assets/scss/elForm.scss" as *;

.container {
  text-align: left;
  line-height: 1;
  display: flex;
  flex-direction: column;

  &_form {
    margin: 0 auto 200px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 1200px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    text-align: center;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &_top {
      line-height: 100px;
      background-color: rgba(39, 174, 96, 0.04);
      text-align: center;
      color: rgba(39, 174, 96, 1);
      font-size: 20px;
      font-weight: bold;
    }

    &_content {
      width: 730px;
      margin: 40px auto;
      display: flex;
      flex-direction: column;

      .labelBtn {
        width: 20px;
        height: 20px;
        padding: 5px !important;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: -30px;
        background-color: #ffffff !important;
        color: rgb(148, 148, 148);
        border-radius: 2px !important;
        font-size: 12px;
      }

      // 产品
      &_product {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;

        &_title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          &_text {
            font-size: 16px;
            color: rgba(24, 24, 24, 1);
            font-weight: bold;
          }

          span {
            font-size: 14px;
            color: rgba(39, 174, 96, 1);
            cursor: pointer;
          }
        }
      }

      .addProduct {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;

        &_btn {
          &:hover {
            color: rgba(39, 174, 96, 1);
            background-color: rgba(39, 174, 96, 0.1);
            border-color: rgba(39, 174, 96, 1);
          }
        }
      }

      .el-item-line {
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to right,
            rgba(39, 174, 96, 1) 0%,
            rgba(39, 174, 96, 1) 50%,
            transparent 50%);
        background-size: 10px 1px;
        background-repeat: repeat-x;
        margin-top: 20px;
        margin-bottom: 50px;
      }

      .address {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 350px;
        height: 52px;
        color: rgba(39, 174, 96, 1);
      }

      .el-upload__text {
        em {
          color: rgba(39, 174, 96, 1);
        }
      }

      .el-btn {
        width: 730px !important;
        display: flex;
        align-items: center;
        margin-top: 30px;

        :deep .el-form-item__content {
          width: auto;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-dialog {
  border-radius: 8px;
  overflow: hidden;

  .el-dialog__header {
    padding: 0 !important;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(248, 248, 248, 1);

    .el-dialog__headerbtn {
      position: static;
    }
  }
}

.my-header {
  height: 80px;
  line-height: 80px;
  border-radius: 8px 8px 0px 0px;
  background-color: rgba(248, 248, 248, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  &_title {
    margin-left: 30px;
    line-height: 80px;
    color: rgba(24, 24, 24, 1);
    font-size: 20px;
    text-align: left;
    font-weight: bold;
  }
}

.el-dialog__body {
  padding: 30px;
}

.dialogContent {
  .dialogContent_text {
    font-size: 16px;
    line-height: 2;
    text-align: justify;
  }

  .dialogContent_submit {
    width: 120px;
    border-radius: 4px;
    background-color: rgba(39, 174, 96, 1);
    padding: 15px 20px;
    margin: 50px auto 30px;
    color: #fff;
    font-weight: 700;
  }
}

.el-form {
  align-items: flex-start;

  .el-form-item {
    width: 350px !important;

    .el-form-item__label {
      width: 320px !important;
    }

    .el-form-item__content .el-input {
      width: 350px;
    }

    .el-upload {
      width: 350px;

      .el-upload-dragger {
        padding: 20px 0;
      }
    }
  }

  .lable {
    width: 400px;
    text-align: left;
    font-size: 14px;
    color: #181818;
    margin-bottom: 10px;
  }
}

.el-input-group {
  margin-bottom: 30px;

  .el-input-group__prepend {
    background-color: rgba(249, 249, 249, 1);
    box-shadow: none;

    .select-trigger {
      .el-input {
        border-color: aquamarine;
        --el-input-border-color: rgba(229, 229, 229, 1);

        .el-input__wrapper {
          height: 46px;
          padding: 0px 20px !important;
          // box-shadow: none;
          border-right: 1px solid rgba(229, 229, 229, 1);
        }
      }
    }
  }

  .el-input-group__prepend .el-select .el-input {
    .el-input__wrapper {
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }
  }

  .is-focus .el-input__wrapper.is-focus {
    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
    border: 1px solid rgba(39, 174, 96, 1);
    box-sizing: border-box;
  }

  .el-input__wrapper {
    padding: 0 20px;
    background-color: rgba(249, 249, 249, 1);
    box-shadow: none;
  }

  .is-focus {
    box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
    border: 1px solid rgba(39, 174, 96, 1);
    box-sizing: border-box;
  }
}
</style>

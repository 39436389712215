<template>
  <div class="container">
    <!-- banner   -->
    <Banner />

    <!-- 订单 -->
    <div class="container_order">
      <!-- 标题加按钮 -->
      <div class="container_order_top">
        <div class="container_order_top_text">My Orders</div>
        <div class="container_order_top_buttons">
          <div v-for="item in buttonList" :key="item.id" :class="[item.active === true ? 'container_order_top_buttons_item1' : 'container_order_top_buttons_item2', 'container_order_top_buttons_item']" @click="getOrderList(item.id)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div v-if="isEmpty" class="container_order_empty">
        <img src="../../assets/images/home/noOrders.png" alt="">
      </div>

      <!-- 已确认的订单 Active Orders -->
      <div v-if="!isEmpty && orderIdCheck === 1" class="container_order_content">
        <div class="container_order_content_item" v-for="order in orderList" :key="order.orderId">
          <div class="container_order_content_item_todo" @click="toOrderDetails(order.orderId)">
            <!-- <img :src="order?.clientImageList.length === 0 ? goodimag : order?.clientImageList[0]?.imageUrl" alt=""> -->
            <img :src="getImageSrc(order)" alt="">
            <div class="container_order_content_item_todo_right">
              <div class="container_order_content_item_todo_right_one">{{ order.clientRequirement.productName }}</div>
              <div class="container_order_content_item_todo_right_two">Quantity: {{ order.clientRequirement.quantity }}</div>
              <div class="container_order_content_item_todo_right_three">Sample processing</div>
            </div>
          </div>
          <div class="container_order_content_item_list" v-for="quotation in order.supplierQuotationList" :key="quotation.quotationId" @click="toQuotation(quotation.quotationId)">
            <div class="container_order_content_item_list_item">
              <div class="container_order_content_item_list_item_left">
                <img src="../../assets/images/home/carbonFootprint.png" alt="">
                <div class="container_order_content_item_list_item_left_co2">{{ thousands(quotation.unitco) }}kg</div>
                <div class="container_order_content_item_list_item_left_text1">CO<sub>2</sub></div>
              </div>
              <div class="container_order_content_item_list_item_right">
                <div class="container_order_content_item_list_item_right_text">{{ quotation.supplierName ? quotation.supplierName : "ABC co." }}</div>
                <img src="../../assets/images/home/chevron_right.png" alt="">
              </div>
            </div>
          </div>

          <!-- 打开弹窗上传信息 -->
          <!-- 大货单 -->
          <div class="container_order_content_item_shipment" @click="toUploadShipmentDetail(order.quotationId)" v-if="i != 2">
            <img src="../../assets/images/supplier/home/ShipmentDetails.png" alt="">
            <div class="container_order_content_item_shipment_text">Shipment details</div>
          </div>
          <!-- 样品单 -->
          <div class="container_order_content_item_shipment" @click="toUploadSampleInformation(order.quotationId)" v-if="i == 2">
            <img src="../../assets/images/supplier/home/UploadSampleInformation.png" alt="">
            <div class="container_order_content_item_shipment_text">Upload sample information</div>
          </div>
        </div>
      </div>

      <!-- 所有订单 -->
      <div v-if="!isEmpty && orderIdCheck === 2" class="container_order_content">
        <div class="container_order_content_item2" v-for="order in orderList" :key="order.orderId">
          <div class="container_order_content_item2_todo" @click="toOrderDetails(order.orderId)">
            <!-- <img :src="order?.clientImageList.length === 0 ? goodimag : order?.clientImageList[0]?.imageUrl" alt=""> -->
            <img :src="getImageSrc(order)" alt="">
            <div class="container_order_content_item2_todo_right">
              <div class="container_order_content_item2_todo_right_one">{{ order.clientRequirement.productName }}</div>
              <div class="container_order_content_item2_todo_right_two">
                Quantity: {{ order.clientRequirement.quantity }}
                <div class="container_order_content_item2_todo_right_two_UnitPrice">
                  Unit Price:<span>{{ order?.supplierQuotationList.length > 0 ? order?.supplierQuotationList[0]?.currency : "" }}{{ order?.supplierQuotationList.length > 0 ? thousand(order?.supplierQuotationList[0]?.unitPrice) : 0 }}</span>
                </div>
              </div>
              <div class="container_order_content_item2_todo_right_three">In transit</div>
            </div>
          </div>
          <div class="container_order_content_item2_list" v-for="quotation in order.supplierQuotationList" :key="quotation.quotationId" @click="toQuotation(quotation.quotationId)">
            <div class="container_order_content_item_list_item order-line">
              <div class="container_order_content_item2_list_item_left">
                <img src="../../assets/images/home/carbonFootprint.png" alt="">
                <div class="container_order_content_item2_list_item_left_co2">{{ thousands(order?.supplierQuotationList[0]?.unitco) }}kg</div>
                <div class="container_order_content_item2_list_item_left_text1">CO<sub>2</sub> </div>
              </div>
              <div class="container_order_content_item2_list_item_right">
                <div class="container_order_content_item2_list_item_right_text">{{ quotation.supplierName ? quotation.supplierName : "ABC co." }}</div>
                <img src="../../assets/images/home/chevron_right.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="container_order_page">
        <pagination v-show="totalOrder > 0" :total="totalOrder" layout="total,  prev, pager, next, jumper" v-model:page="orderParams.pageNum" v-model:limit="orderParams.pageSize" @pagination="onLoadOrderList()" :auto-scroll="false" />
      </div>
    </div>

    <!-- 需求列表 -->
    <div class="container_demand">
      <!-- 标题加按钮 -->
      <div class="container_order_top">
        <div class="container_order_top_text">Order demand</div>
        <div class="container_order_top_buttons">
          <div v-for="item in demandButtonList" :key="item.id" :class="[item.active === true ? 'container_order_top_buttons_item1' : 'container_order_top_buttons_item2', 'container_order_top_buttons_item']" @click="getDemandList(item.id)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div v-if="demandIsEmpty === true" class="container_order_empty">
        <img src="../../assets/images/home/noOrders.png" alt="">
      </div>

      <!-- 待报价 -->
      <div v-if="!demandIsEmpty && demandIdCheck === 1" class="container_order_content">
        <div class="container_order_content_item" v-for="pending in pendingList" :key="pending.clientRequirementId" @click="toRequirementDetails(pending.clientRequirementId)">
          <div class="container_order_content_item_todo">
            <!-- <img :src="pending?.clientImageList.length == 0 ? goodimag : pending?.clientImageList[0]?.imageUrl" alt=""> -->
            <img :src="getImageSrc(pending)" alt="">
            <div class="container_order_content_item_todo_right">
              <div class="container_order_content_item_todo_right_one">{{ pending?.clientRequirement?.productName }}</div>
              <div class="container_order_content_item_todo_right_two">Quantity: {{ pending?.clientRequirement?.quantity }}</div>
            </div>
          </div>
          <div class="container_order_content_item_list">
            <div class="container_order_content_item_list_item">
              <div class="container_order_content_item_list_item_left">
                <img v-if="demandIdCheck != 1" src="../../assets/images/home/carbonFootprint.png" alt="">
                <div class="container_order_content_item_list_item_left_co2" v-if="demandIdCheck != 1">526.08g</div>
                <div class="container_order_content_item_list_item_left_text1" v-if="demandIdCheck != 1">CO<sub>2</sub></div>
              </div>
              <div class="container_order_content_item_list_item_right">
                <div class="container_order_content_item_list_item_right_text">Send A Quotation</div>
                <img src="../../assets/images/supplier/home/SendAQuotation.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 已报价 -->
      <div v-if="!demandIsEmpty && demandIdCheck === 2" class="container_order_content">
        <div class="container_order_content_item2" v-for="quoted in quotedList" :key="quoted.requirementId">
          <div class="container_order_content_item2_todo" @click="toRequirementDetails(quoted.requirementId)">
            <!-- <img :src="quoted?.clientImageList.length == 0 ? goodimag : quoted?.clientImageList[0]?.imageUrl" alt=""> -->
            <img :src="getImageSrc(quoted)" alt="">
            <div class="container_order_content_item2_todo_right">
              <div class="container_order_content_item2_todo_right_one">{{ quoted?.clientRequirement?.productName }}</div>
              <div class="container_order_content_item2_todo_right_two">Quantity: {{ quoted?.clientRequirement?.quantity }}</div>
            </div>
          </div>
          <div class="container_order_content_item2_list" @click="toQuotation(quoted.quotationId)">
            <div class="container_order_content_item2_list_item_left">
              <img src="../../assets/images/home/carbonFootprint.png" alt="">
              <div class="container_order_content_item2_list_item_left_co2">{{ thousands(quoted?.unitco) }}kg</div>
              <div class="container_order_content_item2_list_item_left_text1">CO<sub>2</sub> </div>
            </div>
            <div class="container_order_content_item2_list_item_right">
              <div class="container_order_content_item2_list_item_right_text">{{ quoted?.supplier?.supplierName }}</div>
              <img src="../../assets/images/home/chevron_right.png" alt="">
            </div>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="container_order_page" v-if="!demandIsEmpty && demandIdCheck === 1">
        <pagination v-show="pendingTotal > 0" :total="pendingTotal" layout="total,  prev, pager, next, jumper" v-model:page="queryPending.pageNum" v-model:limit="queryPending.pageSize" @pagination="onLoadPendingList()" :auto-scroll="false" />
      </div>
      <div class="container_order_page" v-if="!demandIsEmpty && demandIdCheck === 2">
        <pagination v-show="quotedTotal > 0" :total="quotedTotal" layout="total,  prev, pager, next, jumper" v-model:page="queryQuoted.pageNum" v-model:limit="queryQuoted.pageSize" @pagination="onLoadQuotedList()" :auto-scroll="false" />
      </div>
    </div>

    <!-- 产品   -->
    <div class="container_product">
      <div class="container_product_top">
        <div class="container_product_top_left">
          <div class="container_product_top_left_text">Product</div>
          <el-input v-model="productInput" placeholder="Find the Perfect Product for You" clearable>
            <template #append>
              <el-button :icon="Search" @click="onLoadProduct()" />
            </template>
          </el-input>
        </div>
        <div class="container_product_top_buttons">
          <div v-for="item in productButtonList" :key="item.id" :class="[item.active === true ? 'container_product_top_buttons_item1' : 'container_product_top_buttons_item2']" @click="getProductListM(item.id)">
            {{ item.categoryName }}
          </div>
        </div>
      </div>
      <div class="container_product_content">
        <div class="container_product_content_item" v-for="product in productList" :key="product.productId" @click="toProductDetail(product.productId)">
          <img :src="product?.productImageList[0]?.imageUrl" alt="" v-if="product?.productImageList">
          <img v-else :src="goodimag" alt="">
          <div class="container_product_content_item_name">
            {{ product.productName }}
          </div>
          <div class="container_product_content_item_foot" v-if="1 > 2">
            <img src="../../assets/images/home/foot.png" alt="">
            <div class="container_product_content_item_foot_text">
              {{ product.carbonFootprintSum }}g CO2
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="container_product_page">
        <pagination v-show="totalProduct > 0" :total="totalProduct" layout="total,  prev, pager, next, jumper" v-model:page="queryProduct.pageNum" v-model:limit="queryProduct.pageSize" @pagination="onLoadProduct()" :auto-scroll="false" />
      </div>
    </div>

    <!-- 上传大货信息弹窗 -->
    <upload-shipment-detail v-on:customEvent="shipmentCustomEvent" :quotationId="quotationId" v-if="showShipment" />

    <!-- 上传样品信息弹窗 -->
    <upload-sample-detail v-on:customEvent="sampleCustomEvent" :quotationId="quotationId" v-if="showSample" />

    <!-- foot   -->
    <Foot />
  </div>
  <!-- 查看碳值 -->
  <el-dialog v-model="showCo2" width="30%" :before-close="handleCloseCo2">
    <template #header="{ close, titleId, titleClass }">
      <div class="my-header">
        <img style="width: 16px;height: 16px" src="../../assets/images/home/carbonFootprint.png" alt="">
        <div class="my-header_co2">582.8g</div>
        <div class="my-header_unit">Co2</div>
      </div>
    </template>
    <div class="dialogCo2">
      <div class="dialogCo2_item">
        <div class="dialogCo2_item_one">Material</div>
        <img src="../../assets/images/home/toRight.png" alt="">
        <div class="dialogCo2_item_two">Factory</div>
        <div class="dialogCo2_item_dashed" />
        <div class="dialogCo2_item_three">102.03g</div>
        <div class="dialogCo2_item_four">CO2</div>
      </div>
      <div class="dialogCo2_item">
        <div class="dialogCo2_item_one">Material</div>
        <img src="../../assets/images/home/toRight.png" alt="">
        <div class="dialogCo2_item_two">Factory</div>
        <div class="dialogCo2_item_dashed" />
        <div class="dialogCo2_item_three">102.03g</div>
        <div class="dialogCo2_item_four">CO2</div>
      </div>
      <div class="dialogCo2_item">
        <div class="dialogCo2_item_one">Material</div>
        <img src="../../assets/images/home/toRight.png" alt="">
        <div class="dialogCo2_item_two">Factory</div>
        <div class="dialogCo2_item_dashed" />
        <div class="dialogCo2_item_three">102.03g</div>
        <div class="dialogCo2_item_four">CO2</div>
      </div>
    </div>

  </el-dialog>
</template>

<script setup name="SupplierHome">

// 客户主页
import { reactive, ref, toRefs, provide } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Search } from '@element-plus/icons-vue'
import Pagination from '@/components/Pagination'
import { thousands } from '@/utils/thousands'
// 引入的页面组件
import Banner from '../../components/Banner.vue'
import Foot from '../../components/Foot.vue'
import UploadShipmentDetail from './dialog/UploadShipmentDetail.vue'
import UploadSampleDetail from './dialog/UploadSampleDetail.vue'
import { querySupplierOrderList } from '@/api/order'
import { getPendingQuotedList, getQuotedList, queryDemandList } from '@/api/demand'
import { getProductList, getProductListCenter, getProductTypeList } from '@/api/product'
import { it } from 'element-plus/es/locale'
import { thousand } from '@/utils/thousand'
const store = useStore()

const totalOrder = ref(1)
const totalDemand = ref(1)
const productInput = ref('')

const orderList = ref([])
const goodimag = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/goodimage.png')
const loading = ref(false)
const finished = ref(false)
const refreshing = ref(false)
const isEmpty = ref(false)
const router = useRouter()

const queryOrder = reactive({ orderParams: { pageSize: 9, pageNum: 1, type: 3, supplierId: '' } })
const { orderParams } = toRefs(queryOrder)

const buttonList = ref([{ id: 1, name: 'Active Orders', active: true }, { id: 2, name: 'All Orders', active: false }])
const demandButtonList = ref([{ id: 1, name: 'Pending Quotes', active: true }, { id: 2, name: 'Quoted', active: false }])

const productButtonList = ref([{ id: null, categoryName: 'All', active: true }])
const categoryList = async () => {
  const result = await getProductTypeList()
  if (result?.code === 200) {
    productButtonList.value = [{ id: null, categoryName: 'All', active: true }]
      .concat(result.data.map(category => ({ ...category, active: false })))
  }
}
categoryList()

const orderIdCheck = ref(1)
const demandIdCheck = ref(1)

const onLoadOrderList = async () => {
  if (refreshing.value) {
    orderList.value = []
    refreshing.value = false
  }
  try {
    const result = await querySupplierOrderList(orderParams.value)
    console.log('result===', result.code)
    if (result.code === 200) {
      console.log(1)
      orderList.value = result.data.rows
      if (orderList.value.length > 0) {
        for (var item of orderList.value) {
          if (item?.supplierQuotationList.length > 0) {
            if (item?.supplierQuotationList[0].currency) {
              if (item?.supplierQuotationList[0].currency === 'EUR') {
                item.supplierQuotationList[0].currency = '€'
              } else if (item?.supplierQuotationList[0].currency === 'USD') {
                item.supplierQuotationList[0].currency = '$'
              } else if (item?.supplierQuotationList[0].currency === 'CNY') {
                item.supplierQuotationList[0].currency = '¥'
              }
            }
          }
        }
      }
      totalOrder.value = result.data.total
      console.log('orderlist.value s id:', orderList.value)
      isEmpty.value = false
    } else {
      console.log(2)
      isEmpty.value = true
    }
  } catch (error) {
    console.log(error)
  }
}

const getOrderList = async (id) => {
  orderIdCheck.value = id
  for (const o of buttonList.value) {
    if (o.id === id) {
      o.active = true
    } else {
      o.active = false
    }
  }
  switch (orderIdCheck.value) {
  case 1:
    orderParams.value.type = 3
    break
  case 2:
    orderParams.value.type = 4
    break
  default:
    break
  }
  onLoadOrderList()
}

const getImageSrc = (demand) => {
  if (demand.clientImageList.length === 0) {
    return goodimag.value
  } else {
    const imageUrl1 = demand.clientImageList[0].imageUrl || ''
    if (imageUrl1.toLowerCase().endsWith('.pdf')) {
      return goodimag.value
    } else {
      return imageUrl1
    }
  }
}

// 需求
const demandIsEmpty = ref(false)

const pendingList = ref([])
const pendingTotal = ref('1')
const queryPending = ref()
queryPending.value = { pageSize: 9, pageNum: 1 }

const quotedList = ref([])
const quotedTotal = ref('1')
const queryQuoted = ref()
queryQuoted.value = { pageSize: 9, pageNum: 1 }

const getDemandList = async (id) => {
  demandIdCheck.value = id
  for (const o of demandButtonList.value) {
    if (o.id === id) {
      o.active = true
    } else {
      o.active = false
    }
  }
  if (id === 1) {
    onLoadPendingList()
  } else {
    onLoadQuotedList()
  }
}
const onLoadPendingList = async () => {
  pendingList.value = []
  const result = await getPendingQuotedList(queryPending.value)
  if (result?.code === 200) {
    pendingList.value = result?.data.rows
    pendingTotal.value = result.data.total
    console.log('pendingList.value:', pendingList.value)
    if (result.data.total > 0) {
      demandIsEmpty.value = false
    } else {
      demandIsEmpty.value = true
    }
  }
}
const onLoadQuotedList = async () => {
  quotedList.value = []
  const result = await getQuotedList(queryQuoted.value)
  if (result?.code === 200) {
    quotedList.value = result?.data.rows
    quotedTotal.value = result.data.total
    console.log('quotedList.value:', quotedList.value)
    if (result.data.total > 0) {
      demandIsEmpty.value = false
    } else {
      demandIsEmpty.value = true
    }
  }
}
onLoadPendingList()

// 产品
const totalProduct = ref(1)
const productList = ref([])
const queryProduct = ref()
queryProduct.value = { pageSize: 12, pageNum: 1, categoryId: null, orderByColumn: 'm.create_time', isAsc: 'desc' }

const getProductListM = async (id) => {
  for (const o of productButtonList.value) {
    if (o.id === id) {
      o.active = true
    } else {
      o.active = false
    }
  }
  queryProduct.value.categoryId = id
  onLoadProduct()
}

const onLoadProduct = async () => {
  productList.value = []
  if (productInput.value != null) {
    queryProduct.value.productName = productInput
  }
  const result = await getProductListCenter(queryProduct.value)
  if (result?.code === 200) {
    productList.value = result?.rows
    totalProduct.value = result.total
    if (result.total > 0) {
      isEmpty.value = false
    } else {
      isEmpty.value = true
    }
  }
}
onLoadProduct()

const showCo2 = ref(false)
const openCo2 = async () => {
  showCo2.value = true
}
const handleCloseCo2 = () => {
  showCo2.value = false
}

const toProductDetail = (productId) => {
  router.push({
    path: 'productDetail',
    query: {
      productId,
      userType: 'ProductOwner'
    }
  })
}

// 跳转到需求详情 --------------------------------------------
const toRequirementDetails = (id) => {
  router.push({
    path: 'requirementDetails',
    query: {
      id
    }
  })
}

// 跳转到订单详情 --------------------------------------------
const toOrderDetails = (orderId) => {
  router.push({
    path: 'orderDetail',
    query: {
      orderId
    }
  })
}

// 跳转到报价页面
const toQuotation = (id) => {
  router.push({
    path: 'quotation',
    query: {
      qid: id
    }
  })
}

// 弹窗 ----------------
const counter = reactive({ value: 2356 })
// 使用 provide 函数向子组件提供数据
provide('counter', counter)

const showShipment = ref(false)
const showSample = ref(false)
const quotationId = ref()

// 打开上传大货单信息弹窗
const toUploadShipmentDetail = (val) => {
  quotationId.value = val
  console.log('quotationid==', quotationId.value)
  showShipment.value = true
}
// 打开上传样品单信息弹窗
const toUploadSampleInformation = (val) => {
  quotationId.value = val
  showSample.value = true
}

// 接收是否关闭大货信息弹窗
const shipmentCustomEvent = (data) => {
  if (data === 'close') {
    showShipment.value = false
  }
}
// 接收是否关闭样品信息弹窗
const sampleCustomEvent = (data) => {
  if (data === 'close') {
    showSample.value = false
  }
}
onLoadOrderList()

</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__header {
    padding: 0;
  }
}

.dialogCo2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 120px 16px 120px;

  &_item {
    margin-bottom: 43px;
    display: flex;
    align-items: center;

    img {
      margin-left: 15px;
      margin-right: 15px;
      width: 16px;
      height: 16px;
    }

    &_one {
      line-height: 16px;
      color: rgba(24, 24, 24, 1);
      font-size: 14px;
      text-align: left;
    }

    &_two {
      line-height: 16px;
      color: rgba(24, 24, 24, 1);
      font-size: 14px;
      text-align: left;
    }

    &_three {
      line-height: 16px;
      color: rgba(39, 174, 96, 1);
      font-size: 14px;
      text-align: right;
      font-weight: bold;
    }

    &_four {
      margin-left: 10px;
      line-height: 16px;
      color: rgba(36, 89, 2, 0.5);
      font-size: 12px;
      text-align: left;
    }

    &_dashed {
      margin-left: 10px;
      margin-right: 10px;
      width: 100px;
      border-top: 2px dashed var(--el-border-color);
    }
  }
}

.my-header {
  height: 80px;
  line-height: 80px;
  border-radius: 8px 8px 0px 0px;
  background-color: rgba(248, 248, 248, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  &_co2 {
    margin-left: 10px;
    line-height: 80px;
    color: rgba(39, 174, 96, 1);
    font-size: 20px;
    text-align: left;
    font-weight: bold;
  }

  &_unit {
    margin-left: 10px;
    line-height: 20px;
    color: rgba(39, 174, 96, 0.8);
    font-size: 14px;
    text-align: left;
  }
}

.demonstration {
  color: var(--el-text-color-secondary);
}

// 主内容
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  // background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 1;

  &_order {
    width: 1600px;
    margin: 40px auto;

    &_page {
      background-color: #ffffff;
      // height: 60px;
      text-align: center;
      display: inline-block;
      display: flex;
      justify-content: center;

      ::v-deep .el-pagination.is-background .el-pager li.is-active {
        background-color: rgba(39, 174, 96, 1);
      }
    }

    &_top {
      display: flex;

      &_text {
        flex: 1;
        line-height: 36px;
        color: rgba(36, 89, 2, 1);
        font-size: 28px;
        text-align: left;
        font-weight: bold;
      }

      &_buttons {
        display: flex;

        &_item {
          padding: 10px 15px 10px 15px;
          margin-left: 15px;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
        }

        &_item1 {
          background-color: rgba(39, 174, 96, 1);
          color: rgba(255, 255, 255, 1);
        }

        &_item2 {
          background-color: rgba(39, 174, 96, 0.06);
          color: rgba(39, 174, 96, 1);
        }
      }
    }

    &_content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 40px;

      // Active Orders
      &_item {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(255, 255, 255, 1);
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        box-sizing: border-box;
        margin-bottom: 15px;
        width: 390px;

        &:last-child:nth-child(3n - 1) {
          margin-right: 405px;
        }

        &_todo {
          height: 80px;
          padding: 30px 30px 0 30px;
          line-height: 20px;
          border-radius: 10px 10px 0px 0px;
          background: linear-gradient(180deg,
              rgba(39, 174, 96, 0.1) 0%,
              rgba(39, 174, 96, 0) 100%);
          display: flex;
          cursor: pointer;

          img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            border: 1px solid rgba(39, 174, 96, 0.1);
          }

          &_right {
            margin-left: 15px;
            height: 80px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            line-height: 16px;

            &_one {
              color: rgba(24, 24, 24, 1);
              font-size: 16px;
              width: 220px;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }

            &_two {
              margin-top: 10px;
              color: rgba(173, 173, 173, 1);
              font-size: 12px;
            }

            &_three {
              margin-top: 16px;
              padding: 5px 10px;
              border-radius: 2px;
              background-color: rgba(39, 174, 96, 0.06);
              color: rgba(36, 89, 2, 0.6);
              font-size: 12px;
              line-height: 1;
            }
          }
        }

        &_list {
          padding: 40px 30px 0px 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &_item {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding-bottom: 18px;
            cursor: pointer;

            &_left {
              flex: 1;
              display: flex;

              img {
                width: 16px;
                height: 16px;
              }

              &_co2 {
                margin-left: 10px;
                line-height: 16px;
                color: rgba(39, 174, 96, 1);
                font-size: 14px;
                text-align: left;
                font-weight: bold;
              }

              &_text1 {
                margin-left: 5px;
                line-height: 16px;
                color: rgba(36, 89, 2, 0.5);
                font-size: 12px;
                text-align: left;
              }
            }

            &_right {
              display: flex;

              &_text {
                line-height: 16px;
                color: rgba(36, 89, 2, 0.6);
                font-size: 14px;
                text-align: right;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        &_shipment {
          display: flex;
          justify-content: center;
          font-size: 0;
          padding: 20px 0;

          img {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }

          &_text {
            font-size: 12px;
            line-height: 16px;
            color: rgba(36, 89, 2, 1);
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }

      &_item2 {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(255, 255, 255, 1);
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        box-sizing: border-box;
        margin-bottom: 15px;
        width: 390px;

        &:last-child:nth-child(3n - 1) {
          margin-right: 405px;
        }

        &_todo {
          height: 80px;
          padding: 30px 30px 0 30px;
          line-height: 20px;
          border-radius: 10px 10px 0px 0px;
          background: linear-gradient(180deg,
              rgba(39, 174, 96, 0.1) 0%,
              rgba(39, 174, 96, 0) 100%);
          display: flex;
          cursor: pointer;

          img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            border: 1px solid rgba(39, 174, 96, 0.1);
          }

          &_right {
            margin-left: 15px;
            height: 80px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            line-height: 16px;

            &_one {
              color: rgba(24, 24, 24, 1);
              font-size: 16px;
              width: 220px;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }

            &_two {
              margin-top: 10px;
              width: 100%;
              color: rgba(173, 173, 173, 1);
              font-size: 12px;
              display: flex;
              justify-content: space-between;

              span {
                color: rgba(255, 151, 0, 1);
                font-weight: 700;
              }
            }

            &_three {
              margin-top: 16px;
              padding: 5px 10px;
              border-radius: 2px;
              background-color: rgba(240, 240, 240, 1);
              color: rgba(173, 173, 173, 1);
              font-size: 12px;
              line-height: 1;
            }
          }
        }

        &_list {
          padding: 30px 30px 10px 30px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          margin-top: 15px;

          &_item {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
            padding-bottom: 18px;

            &_left {
              flex: 1;
              display: flex;

              img {
                width: 16px;
                height: 16px;
              }

              &_co2 {
                margin-left: 10px;
                line-height: 16px;
                color: rgba(39, 174, 96, 1);
                font-size: 14px;
                text-align: left;
                font-weight: bold;
              }

              &_text1 {
                margin-left: 5px;
                line-height: 16px;
                color: rgba(36, 89, 2, 0.5);
                font-size: 12px;
                text-align: left;
              }
            }

            &_right {
              display: flex;

              &_text {
                line-height: 16px;
                color: rgba(36, 89, 2, 0.6);
                font-size: 14px;
                text-align: right;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }

    &_empty {
      width: 300px;
      height: 300px;
      margin: 40px 450px 40px 450px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // 需求列表
  .container_demand {
    width: 1600px;
    margin: 40px auto;

    .container_order {
      &_content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;

        // Active Orders
        &_item {
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
          border: 1px solid rgba(255, 255, 255, 1);
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
          box-sizing: border-box;
          margin-bottom: 15px;
          width: 390px;

          &:last-child:nth-child(3n - 1) {
            margin-right: 405px;
          }

          &_todo {
            height: 80px;
            padding: 30px 30px 0 30px;
            line-height: 20px;
            border-radius: 10px 10px 0px 0px;
            background: linear-gradient(180deg,
                rgba(39, 174, 96, 0.1) 0%,
                rgba(39, 174, 96, 0) 100%);
            display: flex;
            cursor: pointer;

            img {
              width: 80px;
              height: 80px;
              border-radius: 4px;
              border: 1px solid rgba(39, 174, 96, 0.1);
            }

            &_right {
              margin-left: 15px;
              height: 80px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              line-height: 16px;

              &_one {
                color: rgba(24, 24, 24, 1);
                font-size: 16px;
                width: 220px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
              }

              &_two {
                margin-top: 10px;
                color: rgba(173, 173, 173, 1);
                font-size: 12px;
              }

              &_three {
                margin-top: 16px;
                padding: 5px 10px;
                border-radius: 2px;
                background-color: rgba(39, 174, 96, 0.06);
                color: rgba(36, 89, 2, 0.6);
                font-size: 12px;
                line-height: 1;
              }
            }
          }

          &_list {
            padding: 40px 30px 0px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            &_item {
              width: 100%;
              display: flex;
              align-items: center;
              border-bottom: none;
              padding-bottom: 30px;

              &_left {
                flex: 1;
                display: flex;

                img {
                  width: 16px;
                  height: 16px;
                }

                &_co2 {
                  margin-left: 10px;
                  line-height: 16px;
                  color: rgba(39, 174, 96, 1);
                  font-size: 14px;
                  text-align: left;
                  font-weight: bold;
                }

                &_text1 {
                  margin-left: 5px;
                  line-height: 16px;
                  color: rgba(36, 89, 2, 0.5);
                  font-size: 12px;
                  text-align: left;
                }
              }

              &_right {
                display: flex;

                &_text {
                  line-height: 16px;
                  color: rgba(36, 89, 2, 1);
                  font-size: 14px;
                  text-align: right;
                  margin-right: 5px;
                  font-weight: 500;
                }

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }

        &_item2 {
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
          border: 1px solid rgba(255, 255, 255, 1);
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
          box-sizing: border-box;
          margin-bottom: 15px;
          width: 390px;

          &:last-child:nth-child(3n - 1) {
            margin-right: 405px;
          }

          &_todo {
            height: 80px;
            padding: 30px 30px 0 30px;
            line-height: 20px;
            border-radius: 10px 10px 0px 0px;
            background: linear-gradient(180deg,
                rgba(39, 174, 96, 0.1) 0%,
                rgba(39, 174, 96, 0) 100%);
            display: flex;
            cursor: pointer;

            img {
              width: 80px;
              height: 80px;
              border-radius: 4px;
              border: 1px solid rgba(39, 174, 96, 0.1);
            }

            &_right {
              margin-left: 15px;
              height: 80px;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              line-height: 16px;

              &_one {
                color: rgba(24, 24, 24, 1);
                font-size: 16px;
                width: 220px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
              }

              &_two {
                margin-top: 10px;
                width: 100%;
                color: rgba(173, 173, 173, 1);
                font-size: 12px;
              }
            }
          }

          &_list {
            padding: 0px 30px 30px 30px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            &_item {
              width: 100%;
              display: flex;
              align-items: center;
              border-bottom: 1px solid rgba(240, 240, 240, 1);
              padding-bottom: 18px;

              &_left {
                flex: 1;
                display: flex;

                img {
                  width: 16px;
                  height: 16px;
                }

                &_co2 {
                  margin-left: 10px;
                  line-height: 16px;
                  color: rgba(39, 174, 96, 1);
                  font-size: 14px;
                  text-align: left;
                  font-weight: bold;
                }

                &_text1 {
                  margin-left: 5px;
                  line-height: 16px;
                  color: rgba(36, 89, 2, 0.5);
                  font-size: 12px;
                  text-align: left;
                }
              }

              &_right {
                display: flex;

                &_text {
                  line-height: 16px;
                  color: rgba(36, 89, 2, 0.6);
                  font-size: 14px;
                  text-align: right;
                }

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }

      &_empty {
        width: 300px;
        height: 300px;
        margin: 40px 450px 40px 450px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &_product {
    margin-top: 50px;
    width: 1600px;
    margin: 0px auto;

    &_page {
      background-color: #ffffff;
      // height: 60px;
      text-align: center;
      display: inline-block;
      display: flex;
      justify-content: center;

      ::v-deep .el-pagination.is-background .el-pager li.is-active {
        background-color: rgba(39, 174, 96, 1);
      }
    }

    // padding: 40px 360px 40px 360px;

    &_top {
      display: flex;

      &_left {
        display: flex;
        flex: 1;

        &_text {
          line-height: 36px;
          color: rgba(36, 89, 2, 1);
          font-size: 28px;
          text-align: left;
          font-weight: bold;
        }

        .el-input {
          margin-left: 50px;
          width: 350px;
          line-height: 20px;
          border-radius: 2px;
          text-align: center;
        }
      }

      &_buttons {
        display: flex;

        &_item1 {
          padding: 10px 15px 10px 15px;
          margin-left: 15px;
          border-radius: 4px;
          background-color: rgba(39, 174, 96, 1);
          text-align: center;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }

        &_item2 {
          padding: 10px 15px 10px 15px;
          margin-left: 15px;
          border-radius: 4px;
          background-color: rgba(39, 174, 96, 0.06);
          text-align: center;
          color: rgba(39, 174, 96, 1);
          font-size: 14px;
        }
      }
    }

    &_content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;

      &_item {
        // margin: 5px;
        // width: calc(24% - 20px);
        width: 265px;
        // height: 250px;
        line-height: 20px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        text-align: center;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        padding: 10px 10px 24px 10px;
        margin-right: 20px;
        margin-bottom: 20px;

        &:nth-child(4n) {
          margin-right: 0px;
        }

        img {
          width: 260px;
          height: 260px;
          object-fit: cover;
          border-radius: 6px;
        }

        &_name {
          margin-left: 10px;
          margin-top: 20px;
          color: rgba(24, 24, 24, 1);
          font-size: 16px;
          text-align: left;
          font-weight: 600;
          word-wrap: break-word;
          word-break: normal;
        }

        &_foot {
          margin-top: 15px;
          display: flex;

          img {
            margin-left: 10px;
            width: 20px;
            height: 20px;
          }

          &_text {
            margin-left: 10px;
            flex: 1;
            line-height: 20px;
            color: rgba(39, 174, 96, 1);
            font-size: 12px;
            text-align: left;
            font-family: Helvetica-regular;
          }
        }
      }
    }
  }
}

.order-line {
  border: none;
}
</style>

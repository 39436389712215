import { render, staticRenderFns } from "./EditProduct.vue?vue&type=template&id=1e9c92ed&scoped=true"
import script from "./EditProduct.vue?vue&type=script&setup=true&name=EditProduct&lang=js"
export * from "./EditProduct.vue?vue&type=script&setup=true&name=EditProduct&lang=js"
import style0 from "./EditProduct.vue?vue&type=style&index=0&id=1e9c92ed&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e9c92ed",
  null
  
)

export default component.exports